/*============================================================================================*/
/* MEDIA QUERIES size 768/1200px  */
/*============================================================================================*/
@media (min-width: 768px) and (max-width: 1200px) {
	
	.main-menu ul li.megamenu .menu-wrapper {
		width: 940px;
	}
	
	.img_list img {
		left: -25%;
	}
	
	/* New v1.4 */
	#search {
		padding: 100px 15% 0 15%;
	}
}
/* END MEDIA QUERIES size 768/1200px  */

/*============================================================================================*/
/* MEDIA QUERIES max-width 1024px  */
/*============================================================================================*/
@media (max-width: 1024px) {
	
	/* Promo_full (v.1.7)*/
	.promo_full {
		background-attachment: scroll;
	}
}
/* END MEDIA QUERIES max-width 1024 px  */

/*============================================================================================*/
/* LOGO H1 FOR RETINA DISPLAY  */
/*============================================================================================*/
@media only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 991px),
only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 991px),
only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 991px),
only screen and (min-device-pixel-ratio: 2) and (max-width: 991px) {
	
	#logo_home h1 a,
	header#colored #logo_home h1 a {
		background-image: url(../img/logo_2x.png);
		background-size: auto 30px;
	}
	
	header.sticky #logo_home h1 a,
	header#plain #logo_home h1 a {
		background-image: url(../img/logo_sticky_2x.png);
		background-size: auto 30px;
	}
	
	header.sticky#colored #logo_home h1 a {
		background-image: url(../img/logo_sticky_colored_2x.png);
		background-size: auto 30px;
	}
}
/* LOGO H1 FOR RETINA DISPLAY  */

/*============================================================================================*/
/* MEDIA QUERIES max-width 991px  */
/*============================================================================================*/
@media (max-width: 991px) {
	
	ul#top_tools,
	.sticky ul#top_tools {
		margin: 3px 45px 0 0;
	}
	
	.sticky nav {
		margin-top: 3px;
	}
	
	nav {
		margin-top: 15px;
	}
	
	header#plain {
		padding-bottom: 10px;
	}
	
	header#plain.sticky {
		padding-bottom: 0;
	}
	
	header #logo img.logo_normal,
	header #logo img.logo_sticky {
		width: auto;
		height: 30px;
	}
	
	#logo_home h1 a,
	header.sticky #logo_home h1 a,
	header#plain #logo_home h1 a,
	header#colored #logo_home h1 a {
		width: 180px;
		height: 30px;
		background-size: auto 30px;
	}
	
	.main-menu ul li.megamenu .menu-wrapper {
		width: auto;
		margin-top: 20px;
	}
	
	#single_tour_feat ul {
		margin-bottom: 10px;
	}
	
	.review_strip_single.last {
		border-bottom: 1px solid #ddd;
		margin-bottom: 20px;
	}
	
	a#phone,
	a#email_footer {
		font-size: 12px;
		padding-left: 25px;
		margin: 15px 0;
	}
	
	a#phone:before,
	a#email_footer:before {
		font-size: 18px;
	}
	
	/* New v1.4 */
	#search {
		padding: 130px 5% 0 5%;
	}
	
	.plan {
		min-height: inherit;
	}
	
	/* New v1.8 */
	.parallax-content-1 div h1,
	#hero_2 .intro_title h3 {
		font-size: 42px;
	}
	
	.parallax-content-1 div h1:before,
	.parallax-content-1 div h1:after {
		width: 30px;
	}
	
	#hero_2 .intro_title h1:before,
	#hero_2 .intro_title h1:after {
		width: 30px;
	}
	
	#map_filter ul li a {
		min-width: 100px;
	}
	
	#map_filter ul li a span {
		font-size: 11px;
	}
	
}
/* END MEDIA QUERIES max-width 991px  */

/*============================================================================================*/
/* MEDIA QUERIES size 769/979px  */
/*============================================================================================*/
@media (min-width: 769px) and (max-width: 979px) {
	
	#map-container {
		height: 280px;
		position: relative;
		top: 0;
		overflow: hidden;
	}
	
	#content-row {
		height: auto;
		padding-top: 0;
	}
	
	/* New v1.4 */
	.plan-tall + .plan {
		border: solid #dddddd 1px;
	}
	
	.plan-tall {
		margin-right: 0;
	}
	
	.col-md-4.plan:first-child {
		margin-right: 0;
		margin-left: 0;
		border-right: solid #dddddd 1px;
	}
	
	.view_on_map {
		display: none;
	}
	
	.full-height {
		height: auto;
	}
	
	.row-height {
		height: auto;
		min-height: 0;
	}
	
	.content-left {
		height: auto;
		min-height: 0;
		overflow-y: scroll;
	}
	
}
/* END MEDIA QUERIES size 769/979px  */

/*============================================================================================*/
/* MEDIA QUERIES max-width 768px - TABLETS  */
/*============================================================================================*/
@media (max-width: 768px) {
	
	.parallax-content-1 div {
		padding: 0 10%;
		font-size: 14px;
	}
	
	.parallax-content-1 div h3 {
		font-size: 36px;
	}
	
	.step {
		padding: 20px 31px 20px 31px;
	}
	
	.cards {
		margin-top: 0;
		margin-bottom: 20px;
	}
	
	#single_tour_feat ul li i {
		font-size: 28px;
	}
	
	#hero_video a.video {
		display: inline-block;
	}
	
	.login_social {
		margin-bottom: 5px;
	}
	
	/* New v1.4 */
	#search {
		padding: 100px 5% 0 5%;
	}
	
	.view_on_map {
		display: none;
	}
	
	.full-height {
		height: auto;
	}
	
	.row-height {
		height: auto;
		min-height: 0;
	}
	
	.content-left {
		height: auto;
		min-height: 0;
		overflow-y: scroll;
	}
	
	/* New v3.0 */
	#newsletter_container h3 {
		font-size: 22px;
		margin: 0 0 20px 0;
	}
	
	#newsletter_container input.form-control#email_newsletter_2 {
		width: 80%;
		margin: auto auto 15px auto;
	}
	
	footer ul {
	margin: 0;
	padding: 0 0 0 0;
	list-style: none;
	}
	
	.map-right #map{
	height: 450px;
	}
	
}
/* MEDIA QUERIES max-width 768px - TABLETS  */

/*============================================================================================*/
/* MEDIA QUERIES max-width 767px - MOBILE LANDSCAPE  */
/*============================================================================================*/
@media (max-width: 767px) {
	
	#top_line {
		display: none;
	}
	
	.strip_all_tour_list {
		text-align: center;
	}

	.search_bar {
		width: 100%;
	}
	
	.img_list {
		width: 100%;
		margin: auto;
	}
	
	.img_list img {
		left: 0;
		width: 100%;
		height: auto;
		top: -45%;
	}
	
	.tour_list_desc {
		padding: 0 15px;
		border-right: none;
		height: auto;
	}
	
	.price_list {
		display: block;
		height: auto;
		margin-left: 0;
		text-align: center;
		padding: 25px 0 15px 0;
	}
	
	.price_list div {
		display: block;
		text-align: center;
	}
	
	.price_list .button {
		display: block;
		margin: 15px;
	}
	
	.main_title {
		font-size: 14px;
	}
	
	.main_title h2 {
		font-size: 24px;
	}
	
	.main_title p {
		font-size: 16px
	}
	
	/* Mobile navigation for categories left column */
	.styled-select-cat select {
		background: transparent;
		width: 107%;
		padding: 7px 5px 5px 45px;
		border: 0;
		border-radius: 0;
		height: 50px;
		margin: 0;
		font-weight: 400;
		-moz-appearance: window;
		-webkit-appearance: none;
		cursor: pointer;
		color: #888;
	}
	
	.styled-select-cat {
		width: 100%;
		overflow: hidden;
		height: 50px;
		position: relative;
		border: none;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
	}
	
	.styled-select-cat:before {
		font-style: normal;
		font-weight: normal;
		font-family: "icon_set_1";
		font-size: 22px;
		content: "\53";
		position: absolute;
		left: 15px;
		top: 15px;
	}
	
	.styled-select-cat::-ms-expand
	{
		display: none;
	}
	
	.styled-select-cat select:focus {
		outline: none;
	}
	
	/* Responsive table cart */	
	.table.cart-list,
	.table.cart-list thead,
	.table.cart-list tbody,
	.table.cart-list th,
	.table.cart-list td,
	.table.cart-list tr {
		display: block;
		border: 0;
	}
	
	.table.cart-list thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	.table.cart-list tr {
		border: 1px solid #ccc;
	}
	
	.table.cart-list td {
		border: none;
		border-bottom: 1px solid #eee;
		position: relative;
		padding-left: 50%;
	}
	
	.table.cart-list td:before {
		position: absolute;
		top: 12px;
		left: 12px;
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
	}
	
	.table.cart-list td:nth-of-type(1):before {
		content: "Item";
		font-weight: bold;
		color: #111;
	}
	
	.table.cart-list td:nth-of-type(2):before {
		content: "Quantity";
		font-weight: bold;
		color: #111;
	}
	
	.table.cart-list td:nth-of-type(3):before {
		content: "Discount";
		font-weight: bold;
		color: #111;
	}
	
	.table.cart-list td:nth-of-type(4):before {
		content: "Total";
		font-weight: bold;
		color: #111;
	}
	
	.table.cart-list td:nth-of-type(5):before {
		content: "Actions";
		font-weight: bold;
		color: #111;
	}
	
	.thumb_cart {
		display: none;
	}
	
	#single_tour_feat ul {
		display: block;
		margin-bottom: 10px;
	}
	
	#single_tour_feat ul li {
		display: inline-block;
		width: auto;
		font-size: 11px;
	}
	
	/* Margin tools */
	.margin_60 {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	
	.margin_30 {
		margin-top: 15px;
		margin-bottom: 15px;
	}
	
	/* Parallax bg */
	.parallax-window,
	#hero_2 {
		height: 240px;
		min-height: 240px;
	}
	
	.parallax-content-1 {
		height: 240px;
	}
	
	.parallax-content-1 > div {
		padding: 20px 5% 0 5%;
		font-size: 12px;
	}
	
	.parallax-content-1 div h1,
	.parallax-content-1 div h3 {
		font-size: 20px;
		padding-top: 15px;
	}
	
	/* Banners */
	#banner {
		padding: 20px;
	}
	
	.banner a {
		position: relative;
		top: 15px;
		right: 0;
		left: 0;
		margin-bottom: 20px;
	}
	
	.banner h4 {
		font-size: 22px;
	}
	
	/* tooltip */
	.strip_all_tour_list .tooltip-content-flip {
		width: 70px;
	}
	
	/* Single tour page */
	.parallax-content-2 {
		padding-bottom: 15px;
	}
	
	.parallax-content-2 div h1 {
		font-size: 22px;
	}
	
	.parallax-content-2 .rating {
		font-size: 12px;
		display: block;
	}
	
	#price_single_main,
	#price_single_main.hotel {
		font-size: 11px;
		text-align: left;
		padding-top: 0;
	}
	
	#price_single_main span {
		font-size: 20px;
	}
	
	#price_single_main sup {
		font-size: 20px;
		top: 0;
	}
	
	#hero_2 .intro_title h1 {
		font-size: 22px;
	}
	
	.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
		font-size: 12px;
	}
	
	#hero {
		height: 350px;
		font-size: 12px;
	}
	
	/* New v1.2*/
	.intro_title h3 {
		font-size: 26px;
		margin-bottom: 5px;
		padding-top:30px;
		color: #fff;
		font-weight: bold;
		text-transform: uppercase;
	}
	
	.item_cart {
		margin-top: 0;
	}
	
	.intro_title.error h1 {
		font-size: 50px;
	}
	
	/* New v1.4 */
	.radio_fix {
		margin-top: 10px
	}
	
	.col-md-4.plan:first-child,
	.col-md-4.plan:last-child {
		margin-right: 0;
		margin-left: 0;
		border-width: 1px 1px 1px 1px;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	
	.plan-tall + .plan {
		border-left: 1px;
		border: solid #dddddd;
	}
	
	.plan-tall {
		margin-right: 0;
	}
	
	/* New v1.8 */
	#map_filter ul li a {
		min-width: inherit;
	}
	
	#map_filter ul li a span {
		display: none;
	}
	
	#map_filter ul li a i {
		font-size: 24px;
	}
	
	/* New v1.9 */
	#map_contact {
		height: 300px;
	}
	
	#map_contact.contact_2 {
		margin-top: 60px;
	}
	
	.content-left {
		padding-top: 80px;
	}
	
	.map-right #map{
	height: 375px;
	}
	
	/* New v3.0 */
	#top_line.visible_on_mobile{
		display: block;
	}
	
	a#lang_link,
	a#currency_link {
		position: relative;
		text-indent: -999px;
		width: 20px;
		height: 20px;
		display: block;
	}
	
	#lang_link:before,
	#currency_link:before {
		text-indent: 0;
		font-size: 20px;
	}
	
	.dropdown-access .dropdown-menu:before {
	right: 5%;
	}
	
	a#access_link,
	a#wishlist_link {
		position: relative;
		text-indent: -999px;
		width: 20px;
		height: 20px;
		display: block;
	}
	
	#wishlist_link:before,
	#access_link:before {
		text-indent: 0;
		font-size: 20px;
	}
}
/* END MEDIA QUERIES max-width 767px - MOBILE LANDSCAPE  */

/*============================================================================================*/
/* MEDIA QUERIES max-width 480px - MOBILE PORTRAIT  */
/*============================================================================================*/
@media (max-width: 480px) {

	.cmn-toggle-switch {
		display: block !important;
		top: 5px;
		left: 15px;
	}
	#logo {
		margin-left: 40px;
	}
	.features-slider {
		padding: 11% 50px 10% 50px;
	}
	
	.features-content {
		padding: 10%;
	}
	
	.box_style_1 {
		padding: 15px;
	}
	
	.box_style_1 h3.inner {
		margin: -15px -15px 15px -15px;
	}
	
	.img_list img {
		left: 0;
		width: 100%;
		height: auto;
		top: -5%;
	}
	
	.tooltip-item::after {
		width: 180px;
	}
	
	.tooltip-content {
		width: 180px;
		margin: 0 0 20px -90px;
	}
	
	.styled-select-filters {
		margin-bottom: 5px;
	}
	
	.search_bar {
		width: 100%;
	}
	
	#hero {
		height: 250px;
	}
	
	.intro_title {
		/* display: none; */
	}

    .Prodotto h1 {
        font-size: 26px;
    }
    .Prodotto .product-title .rating small{
        color: #333;
    }

    #ProductBookNow {
        position: fixed;
        width: 100%;
        padding: 10px;
        bottom: 0;
        transition: bottom 0.2s;
        border-top: 1px solid #ccc;
        background-color:#fff;
        z-index: 10;
    }

    #ProductBookNow #price_single_main{
        text-align: left;
        font-size: 12px;
        font-weight: normal;
        line-height: 24px;
    }

    #ProductBookNow #price_single_main span {
        font-weight: bold;
        padding: 0;
        font-size: 26px;
        display: block;
    }

    #ProductBookNow #price_single_main span sup {
        font-size: 26px;
    }

    #ProductBookNow .show-booking-box {
        margin: 0;
    }

    .floatingBB {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 9998;
        overflow-y: auto;
        right: -100%;
        max-width: 330px;
    }

    .floatingBB #booking_box{
        margin:0;
    }

    .floatingBB.opened {
        transition: 1s;
        right: 0;
    }

    .hide-booking-box {
        position: fixed;
        top: 0;
        left: -100%;
        z-index: 9999;
        border-radius: 0;
        padding: 5px;
        font-size: 18px;
    }

    .hide-booking-box.opened {
        transition: 1s;
        left: 0;
    }

	ul#top_tools {
		margin: 0;
		font-size:0.8em;
		li {
			padding: 0;
		}
	}
	
	.sticky ul#top_tools {
		margin: 0;
		font-size:0.8em;
		li {
			padding: 0;
		}
	}
	
	.dropdown.dropdown-cart {
		display: none;
	}
	
	.dropdown.dropdown-search {
		font-size: 20px;
	}
	
	#testimonials h2 {
		font-size: 28px;
	}

	#price_single_main{
		text-align: right;
		font-size:14px;
	}

	#price_single_main span {
		font-size:36px;
		padding-left: 10px;
	}
	
	/* New v1.2*/
	#score {
		float: none;
		margin-top: 15px;
	}
	
	.intro_title.error {
		display: table-cell;
	}
	
	.intro_title.error h1 {
		font-size: 36px;
	}
	
	.intro_title.error a.button_intro {
		display: none;
	}
	
	/* New v1.4 */
	#search {
		padding: 100px 5% 0 5%;
	}
	
	/* Promo_full (v.1.7)*/
	.promo_full_wp div h3 {
		font-size: 36px;
	}
	
	.promo_full_wp div a i {
		font-size: 60px;
	}
	
	/* New v1.8 */
	#map_filter ul li a i {
		font-size: 18px;
	}
	
	#search ul.nav.nav-tabs{
    margin-left:0;
    padding-left:0;
	}
	
	#search .nav-tabs > li > a {
		font-size:11px;
		padding:5px 8px;
	}

	#single_tour_feat ul{
		text-align: center;
	}

	.cart-row .tour_list_desc {
		min-height: 50px !important;
		display: inline-block;
        width: 100%;
	}
	.cart-row .price_list {
		height: auto !important;
		min-height: auto !important;
		display: inline-block;
        padding: 10px;
	}
    .cart-row .cart_product_info li {
        display: inline-block;
        margin-right: 5px !important;
    }
	.final-tiles-gallery.social-icons-right .ftg-social {
		right: 0 !important;
	}

}
/* END MEDIA QUERIES max-width 480px - MOBILE PORTRAIT  */