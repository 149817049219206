/*
Theme Name: CITYTOURS
Theme URI: http://www.ansonika.com/citytours/
Author: Ansonika
Author URI: http://themeforest.net/user/Ansonika/

[Table of contents] 

1. SITE STRUCTURE and TYPOGRAPHY
- 1.1 Typography
- 1.2 Buttons
- 1.3 Structure

2. CONTENT
- 2.1 Home
- 2.2 All tours list / All tours grid
- 2.3 Single tour page
- 2.4 Cart - Payment - Confirmation
- 2.5 About
- 2.6 Contact us
- 2.7 Login / register
- 2.8 Tour guide page
- 2.9 Transfer
- 3.0 Hotel
- 3.1 Whishlist
- 3.2 Map listing

3. COMMON
- Tooltips
- Containers styles
- Form styles
- Tabs, collapse
- etc

4. UPDATES 
- 4.1 Home 7
- 4.2 Faq
- 4.3 Pricing tables
- 4.4 Common

5. UPDATES  - Version 3
- 5.1 Ribbons css
- 5.2 Footers versions
- 5.3 Header versions
- 5.4 Gallery overlay
- 5.5 Map + infobox

/*============================================================================================*/
/* 1.  SITE STRUCTURE and TYPOGRAPHY */
/*============================================================================================*/

/*-------- Only fo the demo - menu colors bullets --------*/
i.color_1{color:#e04f67;}
i.color_2{color:#1cbbb4;}
i.color_3{color:#82ca9c;}
i.color_4{color:#f7941d;}

/*-------- Allert for old browsers IE8 and below --------*/
.chromeframe{background:#ddd;color:#000;padding: 0.2em;position: fixed; top: 0; left: 0; text-align: center; z-index:9999; width: 100%;}
.chromeframe a{color:#ed1c24;}

/*-------- 1.1 Typography --------*/
body {background:#f9f9f9; font-size:15px; line-height:24px; font-family:"Lato", Arial, sans-serif; color:#000;-webkit-font-smoothing: antialiased;}

h1, h2, h3, h4, h5, h6{-webkit-font-smoothing:antialiased; color:#333;}
h3{ font-size:22px;}
h4{ font-size:22px;}
h5{ font-size:20px;}
h6{ font-size:18px;}

.main_title{ text-align:center; font-size:16px; margin-bottom:30px;}
.main_title h2{ text-transform: uppercase; font-weight:700; letter-spacing:-1px; font-size:30px; margin-bottom:0;margin-top:0; }
.main_title p{ font-family:"Lato",Arial, sans-serif; font-weight:300; font-size:20px}

h2 span, h3 span, h4 span, h5 span, h6 span{color:#e36f22;}

.box_style_1 h3.inner{ 
	margin:-30px -30px 20px -30px; 
	/*background-color:#e04f67; */
	background-color: #e36f22;
	font-weight: bold;
	padding:15px 20px 15px 18px;
	color:#fff; 
	border:1px solid #fff;
	-webkit-border-top-left-radius: 3px;
	text-align:center;
	-webkit-border-top-right-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-topright: 3px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}
.triangle-down {
	width: 0;
	height: 0;
	border-left: 45px solid transparent;
	border-right: 45px solid transparent;
	border-top: 30px solid #e36f22;
	position:absolute;
	top:50px;
	left:50%;
	margin-left:-45px;
}

/*General links color*/
a {color: #51bce6;text-decoration: none;-webkit-transition: all 0.2s ease;transition: all 0.2s ease; outline:none;}
a:hover,a:focus {color: #111;text-decoration: none; outline:none;}

p {margin: 0 0 20px 0;}

.nopadding {
	margin: 0 !important;
	padding: 0 !important;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #ddd;
}

/*-------- 1.2 Buttons --------*/
a.button_intro, .button_intro  {
	border: none;
	font-family: inherit;
	font-size: inherit;
	color:#fff !important;
	background: #e04f67;
	cursor: pointer;
	padding: 8px 25px;
	display: inline-block;
	outline: none;
	font-size:12px;
	text-align:center;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	text-transform:uppercase;
	font-weight:bold;
	min-width:150px;
}
a.button_intro:hover, .button_intro:hover {
	background: #fff;
	color:#e04f67 !important;
}
a.button_intro.outilne, .button_intro.outline  {
	border: 2px solid #fff;
	color:#fff;
	background: none;
	padding: 6px 23px;
}
a.button_intro:hover, .button_intro:hover {
	background: #fff;
	color:#e04f67;
}

a.button_drop, .button_drop  {
	border: none;
	font-family: inherit;
	font-size: inherit;
	color:#e04f67 !important;
	background: #ededed;
	cursor: pointer;
	padding: 5px 15px !important;
	display: inline-block;
	outline: none;
	font-size:11px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	text-align:center;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	text-transform:uppercase;
	font-weight:bold !important;
	width:49%;
}

a.button_drop.outilne, .button_drop.outline  {
	border: 2px solid #ededed;
	color:#e04f67;
	background: none;
	padding: 3px 23px !important;
}
a.button_drop:hover, .button_drop:hover {
	background:#ededed;
	color:#333;
}
a.button_drop.outline:hover, .button_drop.outline:hover {
	background:#ededed;
	color:#333;
	border: 2px solid #ededed;
}

a.btn_1, .btn_1{
	border: none;
	font-family: inherit;
	font-size: inherit;
	color:#fff;
	background: #51bce6;
	cursor: pointer;
	padding: 7px 20px;
	display: inline-block;
	outline: none;
	font-size:12px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	text-transform:uppercase;
	font-weight:bold;
}
a.btn_1.green, .btn_1.green, a.btn_full.green, .btn_full.green  {background: #83c99f;}
a.btn_1.yellow, .btn_1.yellow, a.btn_full.yellow, .btn_full.yellow  {background: #c9aa4b;}

a.btn_1.white, .btn_1.white  {background: #fff; color:#e04f67;}
a.btn_1.white:hover, .btn_1.white a:hover  {background: #333; color:#fff;}

a.btn_1.outline,.btn_1.outiline, input .btn_1.outiline{
	color:#555;
	background: none;
	border:2px solid #555;
	padding: 5px 18px;
}
a.btn_1.outline:hover,.btn_1.outiline:hover, input .btn_1.outiline:hover{
	color:#fff;
	background:#e04f67;
	border:2px solid #e04f67;
}

a.btn_1.big, .btn_1.big  {
	padding: 15px 30px;
	font-size:20px;
}
a.btn_1.medium, .btn_1.medium  {
	padding: 10px 25px;
	font-size:14px;
}
a.btn_1.small, .btn_1.small  {
	padding: 5px 8px;
	font-size:10px;
	line-height:9px;
	text-transform:none;
	color:#fff !important;
}
a.btn_1:hover, .btn_1:hover {
	background: #333;
}

a.bt_facebook { 	
		color: #fff !important;
	    background:#337ab7;
		font-weight:bold;
		font-size:12px;
		text-decoration:none;
		text-align:center;
		padding:10px 5px;
		border:none;
		display:block;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
	}
a.bt_paypal { 	
		color: #fff !important;
		padding:10px 8px;
	    background:#5bc0de;
		font-weight:bold;
		font-size:12px;
		text-decoration:none;
		text-align:center;
		border:none;
		display:block;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
	}
a.btn_map, input.btn_map{
	border: none;
	font-family: inherit;
	font-size: inherit;
	color:#fff;
	background: #666;
	cursor: pointer;
	padding: 12px 20px;
	display: inline-block;
	outline: none;
	font-size:14px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	text-transform:uppercase;
	font-weight:bold;
	display:block;
	text-align:center;
}
a.btn_map:hover, input.btn_map:hover{
	background:#333;
}

a.btn_full, .btn_full{
	border: none;
	font-family: inherit;
	font-size: inherit;
	color:#fff;
	width:100%;
	background: #e36f22; /**/
	cursor: pointer;
	padding: 12px 20px;
	display: inline-block;
	outline: none;
	font-size:15px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	text-transform:uppercase;
	font-weight:bold;
	display:block;
	text-align:center;
	margin-bottom:10px;
}
a.btn_full:hover, .btn_full:hover{
	background:#333;
}
a.btn_full_outline{
	border: none;
	font-family: inherit;
	font-size: inherit;
	color:#85c99d;
	background:none;
	border:2px solid #85c99d;
	cursor: pointer;
	padding: 10px 20px;
	display: inline-block;
	outline: none;
	font-size:12px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	text-transform:uppercase;
	font-weight:bold;
	display:block;
	text-align:center;
}
a.btn_full_outline:hover{
	border:2px solid #333;
	color:#333;
}


/*-------- 1.3 Structure --------*/

/* Header */


header{
	width:100%;
	position:fixed;
	left:0;
	top:0;
	z-index: 100;
	padding:10px 0;
}
header#plain{background-color:#fff;}
header #logo{margin-bottom:10px;}
header #logo .logo_sticky{ display:none;}
header.sticky #logo .logo_normal{ display:none;}
header.sticky #logo .logo_sticky{ display:block; }

/* logo home with h1 */
#logo_home h1{
	margin:10px 0 0 0;
	padding:0;
}
#logo_home h1 a, header.sticky #logo_home h1 a, header#plain #logo_home h1 a, header#colored #logo_home h1 a{
	width:160px;
	height:34px;
	display:block;
	text-indent:-9999px;
}
header.sticky #logo_home h1{
	margin:0 0 10px 0;
	padding:0;
}
@media only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2) {
}

header.sticky #top_line { 
	height: 0;
	overflow:hidden; 
	padding:0; 
	opacity:0;
	transition: all 0.2s ease-in-out;
	 -moz-transition: all 0.1s ease-in-out;
	 -webkit-transition: all 0.1s ease-in-out;
	 -o-transition: all 0.1s ease-in-out;
}

header.sticky{ 
	-webkit-box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.41);
	-moz-box-shadow:0px 2px 10px -2px rgba(0, 0, 0, 0.41);
	box-shadow:0px 2px 10px -2px rgba(0, 0, 0, 0.41);
	background-color:#fff; 
	padding-bottom:0;
}

nav{ margin-top:20px; position:relative;}
.sticky nav{ margin-top:10px}
.sticky #logo{ margin-top:0; margin-bottom:10px;}

ul#top_tools{list-style:none; padding:0; position:absolute; right:15px; top:-20px; z-index:999;}
ul#top_tools li{display:inline-block; padding:0 5px;}
ul#top_tools a{ color:#fff;position:relative;
	display:inline-block;}
.sticky ul#top_tools a{ color:#333; }


.sticky ul#top_tools{top:-7px;}

#top_line{
	color:#fff;
	height:28px;
	font-size:12px;
	border-bottom:1px solid rgba(255,255,255,0.2);
	 font-size:11px;
	 visibility:visible;
	 opacity:1;
	 margin-bottom:5px;
	 position:relative;
	 z-index:999999;
}
ul#top_links{
	list-style:none;
	margin:0;
	padding:0;
	float:right;
}

ul#top_links li{
	display:inline-block;
	border-left: 1px solid rgba(255,255,255,0.3);
	margin-right:5px;
	padding-left:8px;
	position:relative;
}
ul#top_links li:first-child{
	border-left: none;
	padding-left:0;
}
ul#top_links a{
	color:#fff;
}
ul#top_links a:hover{
	color:#e04f67;
}

/* Header  plain*/
header#plain{background-color:#fff;padding-bottom:0;}
header#plain.sticky{
	-webkit-box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.41);
	-moz-box-shadow:    0px 2px 10px -2px rgba(0, 0, 0, 0.41);
	box-shadow:         0px 2px 10px -2px rgba(0, 0, 0, 0.41);
	background-color:#fff; 
	position:fixed;
	left:0;
	top:0;
	z-index:200;
}
header#plain.sticky #logo .logo_normal{ display:none;}
header#plain.sticky #logo .logo_sticky{ display:block; }
header#plain #top_line{color:#888;border-bottom:1px solid rgba(0,0,0,0.1);}
header#plain ul#top_links li{border-left: 1px solid rgba(0,0,0,0.1);}
header#plain ul#top_links li:first-child{border-left:none;}
header#plain ul#top_tools a{ color:#333; }
header#plain ul#top_links a{ color:#888; }
header#plain ul#top_links :hover a{ color:#333; }

.subheader_plain{margin-top:60px;}

/* Header  colored*/
header#colored.sticky{background-color:#e04f67;padding-bottom:0; border-bottom:2px solid #e04f67}
header#colored.sticky ul#top_tools a {color:#fff;}

/* Drop down cart / login / search  */
.dropdown.dropdown-search, .dropdown.dropdown-cart{
	/*float:right;*/
	font-size:15px !important;
}
.dropdown-cart .dropdown-menu, .dropdown-access .dropdown-menu, .dropdown-search .dropdown-menu {
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  float: right;
  left: auto;
  min-width: 0;
  padding: 15px;
  right: 0;
  width: 250px;
  top:100%;
  font-size:12px;
  font-weight: normal;
    text-shadow:none;
	text-transform:none !important;
	border-top: 2px solid #e04f67;
}

.dropdown-cart .dropdown-menu:before, .dropdown-access .dropdown-menu:before, .dropdown-search .dropdown-menu:before {
	bottom: 100%;
	right: 10%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-bottom-color: #e04f67;
	border-width: 7px;
	margin-left: -7px;
}
.dropdown-menu{
	margin-top:14px;
}
ul#cart_items{
	padding:5px 0 0 0;
	list-style:none;
}
ul#cart_items li{
	border-bottom:1px solid #ededed;
	position:relative;
	margin:0;
	padding:5px 10px;
	padding-bottom:10px;
	width:100%;
	clear:both;
}
ul#cart_items li .image{
   border: 1px solid #ddd;
   overflow:hidden;
   width:50px;
   height:50px;
   float:left;
   margin-right:10px;
}
ul#cart_items li .image img{
   padding:1px;
  width:48px;
  height:auto;
}
ul#cart_items li strong {
	font-weight:normal;
	float:left;
	color:#999;
	margin-top:10px;
	float:left;
	width:150px;
	line-height:14px;
}
ul#cart_items li strong > a{
	color:#333;
	display: block;
}
ul#cart_items li strong > a:hover{
	color:#e04f67;
}
ul#cart_items li a.action {
	color:#999;
	position: absolute;
  font-size: 14px;
  right: 10px;
  top: 0;
  width:15px;
  height:15px;
}
ul#cart_items li a.action:hover {
	color:#333;
	background:none;
}
ul#cart_items li:last-child div{
  color: #999;
  text-align:right;
  font-size:14px;
  padding:10px 0 15px 0;
  display:block;
}
ul#cart_items li:last-child span{
    color: #333;
	font-weight:bold;
	font-size:16px;
}
.dropdown-cart .dropdown-menu a.button_drop{
	display:inline-block;
}
.dropdown- .dropdown-menu{
	margin-top:7px;
}
.login-or {
    position: relative;
    font-size: 16px;
    color: #aaa;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .span-or {
    display: block;
    position: absolute;
    left: 50%;
    top: 2px;
    margin-left: -30px;
    background-color: #fff;
    width: 60px;
    text-align: center;
  }
  .hr-or {
    background-color: #cdcdcd;
    height: 1px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
a#forgot_pw{
	color:#999 !important;
	font-size:11px;
	padding:0;
	margin:-5px 0 10px 0;
	display:block;
  }
  a#forgot_pw:hover{
	color:#333 !important;
  }
  
.dropdown-search .dropdown-menu{
	margin-top:15px;
	right:-25px;
}
.input-group button { background-color:#333; color:#fff; border-color:#333}
.input-group button:hover, .input-group button:focus { background-color:#e04f67; color:#fff; border-color:#e04f67}

/* Footer */
footer {
	background: #333;
	color: #fff;
	padding: 10px 0 10px 0;
}

footer h3 {
	font-size: 16px;
	font-weight: 700;
	color: #fff;
}

footer a {
	color: #fff;
}

footer a:hover {
	color: #999;
}

footer ul {
	margin: 0;
	padding: 0 0 10px 0;
	list-style: none;
}

#social_footer {
	text-align: center;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	padding-top: 10px;
	margin-top: 10px;
}

#social_footer p {
	font-size: 12px;
	color: #8c8c8c;
}

#social_footer ul {
	margin: 0;
	padding: 0 0 10px 0;
	text-align: center;
}

#social_footer ul li {
	display: inline-block;
	margin: 0 5px 10px 5px;
}

#social_footer ul li a {
	color: #fff;
	text-align: center;
	line-height: 34px;
	display: block;
	font-size: 16px;
	width: 35px;
	height: 35px;
	border: 1px solid rgba(255, 255, 255, 0.3);
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}

#social_footer ul li a:hover {
	border: 1px solid #fff;
	background: #fff;
	color: #111;
}

a#phone,
a#email_footer {
	display: block;
	position: relative;
	color: #fdf7ac;
	font-size: 16px;
	padding-left: 40px;
	margin: 20px 0;
}

a#phone:hover,
a#email_footer:hover {
	color: #fff;
}

a#phone:before,
a#email_footer:before {
	font-style: normal;
	font-weight: normal;
	font-family: "icon_set_1";
	position: absolute;
}

a#phone:before {
	font-size: 30px;
	content: "\79";
	left: 0;
	top: 0;
}

a#email_footer:before {
	font-size: 28px;
	content: "\74";
	left: 0;
	top: 5px;
}

/*============================================================================================*/
/* 2.  CONTENT  */
/*============================================================================================*/

/*-------- 2.1 Home --------*/
.feature_home {
	padding: 30px;
	position: relative;
	background: #fff;
	margin-bottom: 30px;
	color: #888;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	text-align: center;
}

.feature_home h3 {
	font-size: 20px;
}

.feature_home i {
	margin: auto;
	margin-bottom: 20px;
	display: block;
	width: 120px;
	height: 120px;
	line-height: 110px;
	text-align: center;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	border: 1px dashed #ccc;
	font-size: 62px;
	color: #6dcff6;
}

.other_tours ul {
	list-style: none;
	padding: 0;
	margin: 0 0 0 0;
}

.other_tours ul li a {
	border-bottom: 1px solid #ededed;
	padding: 5px 0 10px 0;
	display: block;
	color: #333;
}

.other_tours ul li a:hover {
	background-color: #f9f9f9;
	color: #e04f67;
}

.other_tours ul li:last-child a {
	border-bottom: none;
}

.other_tours ul li a i {
	font-size: 22px;
	margin-right: 5px;
	margin-left: 2px;
	position: relative;
	top: 5px;
	color: #555;
}

.other_tours_price {
	float: right;
	margin-top: 5px;
	margin-right: 5px;
	color: #888;
}

/* Home 2 */
#hero {
	position: relative;
	height: 430px;
	background: #4d536d no-repeat 0 0;
	background-size: cover;
	color: #fff;
	width: 100%;
	font-size: 16px;
	display: table;
	z-index: 99;
	text-align: center;
	text-transform: uppercase;
}

.intro_title {
	display: table-cell;
	vertical-align: middle;
}

.intro_title h3 {
	font-size: 45px;
	margin-bottom: 5px;
	color: #fff;
	font-weight: bold;
	text-transform: uppercase;
}

.intro_title h3 strong span {
	color: #fff;
}

/* New v1.2*/
.intro_title.error h1 {
	font-size: 130px;
	color: #fff;
	font-weight: bold;
}

/* Home 3 */
.header-video {
	position: relative;
	overflow: hidden;
	/*background: #4d536d url(../img/slide_hero.jpg) no-repeat center center;*/
	background-size: cover;
}

#hero_video {
	position: relative;
	background-size: cover;
	color: #fff;
	width: 100%;
	font-size: 16px;
	display: table;
	height: 100%;
	z-index: 99;
	text-align: center;
	text-transform: uppercase;
}

#hero_video a.video {
	display: none;
}

iframe.video,
video {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

iframe.video {
	height: 100%;
	width: 100%;
}

video {
	width: 100%;
}

.teaser-video {
	width: 100%;
	height: auto;
}

.header-video--media {
	width: 100%;
	height: auto;
}

/* Home 5 */
#search_bar_container {
	padding: 0 0 15px 0;
	
	width: 100%;
	text-align: center;
}

.search_bar {
	position: relative;
	width: 50%;
	margin: auto;
}

#nav-search-in {
	display: inline-block;
	height: 45px;
	left: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	background: #6ccef5;
}

#nav-search-in #nav-search-in-content {
	color: #fff;
	display: inline-block;
	font-size: 12px;
	text-transform: uppercase;
	height: 45px;
	line-height: 35px;
	margin: 5px 65px 0 15px;
	text-indent: 1px;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 60px;
	overflow: visible;
}

#nav-search-in.nav-facade-active .nav-down-arrow {
	display: inline-block;
	right: 15px;
	top: 20px;
	background: url(../img/arrow_down_2.png);
	height: 6px;
	width: 10px;
	position: absolute;
}

.nav-facade-active #searchDropdownBox {
	cursor: pointer;
	height: 45px;
	left: 15px;
	opacity: 0;
	top: 0;
	visibility: visible;
	width: auto;
}

#searchDropdownBox {
	background: none repeat scroll 0 0 white;
	border: 1px solid #DDDDDD;
	color: black;
	font-family: "arial", "sans-serif";
	left: 5px;
	margin: 0;
	outline: medium none;
	padding: 0;
	position: absolute;
	top: 0;
	visibility: hidden;
	width: 100px;
}

.nav-searchfield-outer {
	border: none;
	background: #fff;
	color: #ccc;
	display: block;
	font-size: 15px;
	left: 0;
	padding: 5px;
	position: relative;
	width: 100%;
	font-family: Arial, Helvetica, sans-serif;
	height: 45px;
	font-weight: 600;
	margin-top: 1px;
}

#twotabsearchtextbox {
	background: #FFF;
	width: 100%;
	border: none;
	box-shadow: none;
	color: #333;
	font-size: 16px;
	height: 30px;
	padding: 3px 0 0 20px;
	outline: none;
	font-weight: normal;
	font-family: 'Lato';
}

.nav-submit-button {
	background: #fff url(../img/search-bt.png) no-repeat center center;
	border: none;
	cursor: pointer;
	display: block;
	height: 45px;
	right: 5px;
	position: absolute;
	text-indent: -9999px;
	top: 0;
	width: 40px;
}

.nav-submit-button .nav-submit-input {
	background: #fff;
	border: medium none;
	color: white;
	cursor: pointer;
}

/*-------- 2.2 All tours list / All tours grid --------*/
.box_style_cat {
	background: #fff;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	border: 1px solid #ddd;
	margin-bottom: 25px;
}

ul#cat_nav {
	list-style: none;
	margin: 0;
	padding: 0;
}

ul#cat_nav li {
	border-bottom: 1px solid #ddd;
}

ul#cat_nav li a {
	position: relative;
	color: #777;
}

ul#cat_nav li a span {
	font-size: 11px;
	color: #999;
}

ul#cat_nav li a:after {
	font-family: "fontello";
	content: "\e89b";
	position: absolute;
	right: 15px;
	top: 50%;
	margin-top: -12px;
}

ul#cat_nav li:last-child {
	border-bottom: 0;
	padding-bottom: 0;
}

ul#cat_nav li:first-child a:hover,
ul#cat_nav li:first-child a#active,
ul#cat_nav li:first-child a.active {
	-webkit-border-top-left-radius: 3px;
	-webkit-border-top-right-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-topright: 3px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}

ul#cat_nav li:last-child a:hover,
ul#cat_nav li:last-child a#active,
ul#cat_nav li:last-child a.active {
	-webkit-border-bottom-right-radius: 3px;
	-webkit-border-bottom-left-radius: 3px;
	-moz-border-radius-bottomright: 3px;
	-moz-border-radius-bottomleft: 3px;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}

ul#cat_nav li a {
	display: block;
	padding: 15px 10px;
}

ul#cat_nav li a:hover,
ul#cat_nav li a#active,
ul#cat_nav li a.active {
	background: #f9f9f9;
	color: #111;
}

ul#cat_nav i {
	font-size: 22px;
	margin-right: 5px;
	float: left;
}

#tools {
	background: #eee;
	padding: 5px;
	margin-bottom: 15px;
}

/* Map */
.marker_info {
	width: 280px;
	height: 320px;
	border-radius: 3px;
	text-align: center;
	background: #FFF;
	background: rgba(255, 255, 255, 0.9);
	position: relative;
	z-index: 9999999;
	font-family: "Montserrat", Arial, sans-serif;
}

.marker_info img {
	display: block;
	-webkit-border-top-left-radius: 3px;
	-webkit-border-top-right-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-topright: 3px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}

.marker_info h3 {
	font-size: 18px;
	line-height: 18px;
	text-transform: uppercase;
	margin-bottom: 10px;
}

.marker_info span {
	display: block;
	padding: 0px 20px;
	font-size: 12px;
	line-height: 16px;
	color: #7a7f82;
	margin-bottom: 0;
}

.marker_info:after {
	right: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(0, 0, 0, 0);
	border-right-color: rgba(255, 255, 255, 0.9);
	border-width: 10px;
	margin-top: -10px;
}

.strip_all_tour_list {
	margin-bottom: 30px;
	background-color: #fff;
	display: block;
	color: #777;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.strip_all_tour_list h3 {
	text-transform: uppercase;
}

.tour_list_desc .rating {
	margin: 15px 0 3px -3px;
	font-size: 15px;
}

.img_list {
	overflow: hidden;
	min-height: 220px;
	text-align: center;
	position: relative;
}

.img_list img {
	width: auto;
	height: 220px;
	position: absolute;
	left: -10%;
}

.img_list a img {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
	-webkit-backface-visibility: hidden;
}

.img_list a:hover img {
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
}

.img_list .short_info {
	padding: 5px 5px 5px 5px;
	text-align: left;
	min-height: 38px;
}

.price_list .button {
	display: block;
}

.tour_list_desc {
	padding: 10px 20px 0 0;
	border-right: 1px solid #ededed;
	height: 220px;
	line-height: 22px;
}

.tour_list_desc h3 {
	font-size: 18px;
	line-height: 26px;
	margin-top: 0;
	margin-bottom: 10px;
}

.price_list {
	display: table;
	height: 220px;
	font-size: 38px;
	color: #e74c3c;
	width: 100%;
	margin-left: -15px;
}

.price_box {
	font-size: 24px;
	color: #e74c3c;
	font-weight: bold;
}
.tour_title .price_box {
	position:absolute;
	bottom: 10px;
	right: 10px;
}
.ftg-social .price_box{
	color:#FFF;
}

.price_list small, .price_box small {
	font-size: 11px;
	color: #999;
	display: block;
	margin-bottom: 10px;
	line-height: 12px;
}
.price_box small {
	margin-bottom: 0;
}
.ftg-social .price_box small {
	color:#f1f1f1;
	margin-bottom: 5px;
}

.price_list div {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
}

.price_list p, .price_box p {
	padding: 0;
	margin: 15px 0 0 0;
}

.price_list .normal_price_list, .price_box .normal_price_list {
	text-decoration: line-through;
	color: #999;
	font-size: 20px;
	margin-bottom: 5px;
}

.price_list sup, .price_box sup {
	font-size: 22px;
	position: relative;
	top: 0px;
}
.ftg-social .price_box sup {
	font-size: 14px;
}
.price_box sup {
	top: 0px;
}

ul.add_info {
	list-style: none;
	padding: 0;
	margin: 0;
}

ul.add_info li {
	display: inline-block;
	margin-right: 5px;
	border: 1px solid #ededed;
	text-align: center;
	width: 35px;
	height: 35px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}

ul.add_info li a {
	color: #555;
	width: 35px;
	height: 35px;
	display: block;
}

ul.add_info li i {
	display: inline-block;
	font-size: 22px;
	top: 6px;
	position: relative;
}

#filters_col {
	background-color: #fff;
	padding: 15px 10px 15px 15px;
	border: 1px solid #ddd;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	margin-bottom: 25px;
}

#filters_col label {
	color: #999;
	font-weight: normal;
}

a#filters_col_bt {
	display: block;
	color: #888;
}

a#filters_col_bt .icon_set_1_icon-65 {
	font-size: 20px;
	position: relative;
	top: 0;
	margin-right: 5px;
}

.filter_type h6 {
	border-top: 1px solid #ddd;
	margin: 15px 0;
	padding: 15px 0 0 0;
}

.filter_type ul {
	list-style: none;
	padding: 0;
	margin: 0 0 15px 0;
}

.filter_type ul li .rating {
	font-size: 16px;
	left: -7px;
	position: relative;
	top: 3px;
}

/*-------- 2.3 Single tour page --------*/
.imgCarousel .sp-selected-thumbnail {
	border-bottom: 2px solid #e36f22;
}
.parallax-content-3 {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 20;
	padding: 18px 0 20px 0;
	color: #fff;
	font-size: 13px;
	background: url(../img/shadow_top.png) repeat-x top left;
	width: 100%;
	min-height: 96px;
}

.parallax-content-2 {
	position: absolute;
	left: 0;
	bottom: 0;
	z-index: 99;
	padding: 18px 0 20px 0;
	color: #fff;
	font-size: 13px;
	background: url(../img/shadow_single.png) repeat-x bottom left;
	width: 100%;
}

.parallax-content-2 div h1 {
	font-size: 36px;
	text-transform: uppercase;
	font-weight: 900;
	color: #fff;
	margin: 0;
}

.parallax-content-2 .rating {
	font-size: 14px;
}

#price_single_main {
	text-align: right;
	padding-top: 25px;
	font-weight: bold;
}

#price_single_main span {
	font-size: 60px;
	color: #e36f22;
}

#price_single_main sup {
	font-size: 22px;
	position: relative;
	top: -25px;
}

#price_single {
	background-color: #e74c3c;
	color: #fff;
	font-size: 36px;
	margin: -15px;
	padding: 20px 15px 10px 15px;
	-webkit-border-top-left-radius: 3px;
	-webkit-border-top-right-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-topright: 3px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	margin-bottom: 15px;
}

#price_single small {
	font-size: 12px;
}

#single_tour_feat ul {
	list-style: none;
	padding: 0;
	margin: 0;
	display: table;
	border-bottom: 2px solid #555;
	margin-bottom: 30px;
}

#single_tour_feat ul li {
	display: table-cell;
	width: 1%;
	vertical-align: middle;
	text-align: center;
	padding: 0 10px 20px 10px;
	white-space: nowrap;
}

#single_tour_feat ul li:last-child {
	margin-right: 0;
}

#single_tour_feat ul li i {
	font-size: 36px;
	display: block;
	margin-bottom: 10px;
}

#content h3 {
	font-size: 22px;
	margin-top: 5px;
}

#content h4 {
	line-height: 20px;
	font-size: 18px;
}

.map {
	width: 100%;
	height: 450px;
	border-bottom: 1px solid #ddd;
	border-top: 1px solid #ddd;
	margin-bottom: 30px;
}

.table.table_summary td {
	border-top: 1px solid #ededed;
	width: 50%;
}

.table.table_summary tr.total {
	color: #e36f22;
	font-size: 20px;
	font-weight: bold;
	text-transform: uppercase;
}

.review_strip_single {
	position: relative;
	padding: 30px 0 20px 0;
	margin: 30px 0 25px 0;
	border-bottom: 1px solid #ddd;
}

.review_strip_single.last {
	margin-bottom: 0;
	border-bottom: 0;
}

.review_strip_single img {
	top: -15px;
	position: absolute;
	left: 0;
	border: 4px solid #fff;
}

.review_strip_single h4 {
	font-size: 18px;
	margin: -12px 0 35px 90px;
	padding: 0;
}

.review_strip_single small {
	float: right;
	font-size: 12px;
	margin-top: -10px;
	font-style: italic;
}

.review_strip_single .rating {
	font-size: 16px;
}

.normal_price_single {
	font-size: 18px;
	text-decoration: line-through;
}

.price_in {
	font-size: 42px;
	color: #e74c3c;
	border-bottom: 1px solid #ededed;
	text-align: center;
	padding-top: 30px;
	background-color: #fff8e5;
	margin: -20px -20px 20px -20px;
}

.price_in small {
	font-size: 11px;
	color: #999;
	display: block;
	margin-bottom: 20px;
	margin-top: 4px;
}

.price_in p {
	padding: 0;
	margin: 0;
}

.normal_price_in {
	display: block;
	text-decoration: line-through;
	color: #999;
	font-size: 20px;
	margin-top: 10px;
}

.price_in sup {
	font-size: 22px;
	position: relative;
	top: -10px;
}

.table.options_booking td i {
	font-size: 26px;
}

.table.options_booking td {
	vertical-align: middle;
}

/*Update version 1.1*/
#general_rating {
	font-size: 18px;
	margin: 10px 0 20px 0;
}

#general_rating .rating {
	display: inline;
}

#rating_summary ul {
	list-style: none;
	margin: 0 0 -5px 0;
	padding: 0;
}

#rating_summary ul li {
	margin-bottom: 5px;
}

#rating_summary ul li .rating {
	display: inline-block;
	font-size: 14px;
	float: right;
}
/*End Update version 1.1*/

/*-------- 2.4 Cart - Payment - Confirmation --------*/
#hero_2 {
	position: relative;
	height: 470px;
	/*background: #4d536d url(../img/slide_hero_2.jpg) no-repeat center center;*/
	background-size: cover;
	color: #fff;
	width: 100%;
	font-size: 16px;
	display: table;
	text-align: center;
}

#hero_2 .intro_title h1 {
	font-size: 38px;
	margin-bottom: 15px;
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
}

#hero_2 .intro_title h1:before,
#hero_2 .intro_title h1:after {
	display: inline-block;
	width: 60px;
	height: 1px;
	content: '';
	vertical-align: middle;
	background: rgba(255, 255, 255, 0.5);
}

#hero_2 .intro_title h1:before {
	margin-right: 10px;
}

#hero_2 .intro_title h1:after {
	margin-left: 10px;
}

.bs-wizard {
	width: 90%;
	margin: auto;
}

.bs-wizard > .bs-wizard-step {
	padding: 0;
	position: relative;
}

.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
	font-size: 16px;
	margin-bottom: 5px;
}

.bs-wizard > .bs-wizard-step .bs-wizard-info {
	color: #999;
	font-size: 14px;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
	position: absolute;
	width: 30px;
	height: 30px;
	display: block;
	background: #fbe8aa;
	top: 45px;
	left: 50%;
	margin-top: -15px;
	margin-left: -15px;
	border-radius: 50%;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {
	content: ' ';
	width: 14px;
	height: 14px;
	background: #e04f67;
	border-radius: 50px;
	position: absolute;
	top: 8px;
	left: 8px;
}

.bs-wizard > .bs-wizard-step > .progress {
	position: relative;
	border-radius: 0px;
	height: 8px;
	box-shadow: none;
	margin: 23px 0;
}

.bs-wizard > .bs-wizard-step > .progress > .progress-bar {
	width: 0px;
	box-shadow: none;
	background: #fbe8aa;
}

.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {
	width: 100%;
}

.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {
	width: 50%;
}

.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {
	width: 0%;
}

.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {
	width: 100%;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {
	background-color: #f5f5f5;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {
	opacity: 0;
}

.bs-wizard > .bs-wizard-step:first-child > .progress {
	left: 50%;
	width: 50%;
}

.bs-wizard > .bs-wizard-step:last-child > .progress {
	width: 50%;
}

.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot {
	pointer-events: none;
}

.form_title {
	position: relative;
	padding-left: 55px;
	margin-bottom: 10px;
}

.form_title h3 strong {
	background-color: #e36f22;
	text-align: center;
	width: 40px;
	height: 40px;
	display: inline-block;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	color: #fff;
	font-size: 18px;
	line-height: 40px;
	position: absolute;
	left: 0;
	top: 0;
}

.form_title h3 {
	margin: 0;
	padding: 0;
}

.form_title p {
	color: #999;
	margin: 0;
	padding: 0;
	font-size: 12px;
	line-height: 14px;
}

.step {
	border-left: 1px solid #ddd;
	padding: 20px 0 20px 31px;
	margin: 0 0 10px 20px;
}

.cards {
	margin-top: 18px;
}

.table {
	border-bottom: 1px solid #ddd;
}

.table.options_cart td i {
	font-size: 38px;
}

.table.cart-list th,
.table.options_cart th {
	background-color: #fff;
	text-transform: uppercase;
}

.table.cart-list td {
	vertical-align: middle;
}

.table.options_cart td {
	vertical-align: middle;
}

.numbers-row {
	position: relative;
	width: 97px;
	height: 40px;
	overflow: visible;
}

.numbers-row.list {
	margin: auto;
	margin-bottom: 5px;
	margin-top: 15px;
}

input.qty2 {
	position: relative;
	width: 35px;
	height: 40px;
	border-radius: 0;
	text-align: center;
	left: 31px;
	font-size: 12px;
	padding: 5px;
}

.button_inc {
	text-indent: -9999px;
	cursor: pointer;
	position: absolute;
	width: 33px;
	height: 40px;
	z-index: 9;
}

.dec {
	background: #fff url(../img/minus.png) no-repeat center center;
	border: 1px solid #cccccc;
	left: 0;
	top: 0;
	-webkit-border-top-left-radius: 4px;
	-webkit-border-bottom-left-radius: 4px;
	-moz-border-radius-topleft: 4px;
	-moz-border-radius-bottomleft: 4px;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.inc {
	background: #fff url(../img/plus.png) no-repeat center center;
	right: 0;
	top: 0;
	border: 1px solid #cccccc;
	-webkit-border-top-right-radius: 4px;
	-webkit-border-bottom-right-radius: 4px;
	-moz-border-radius-topright: 4px;
	-moz-border-radius-bottomright: 4px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

#total {
	text-align: right;
	margin-top: 20px;
}

#total h3 {
	font-size: 26px;
	line-height: 26px;
	padding: 0;
	font-weight: bold;
	display: block;
	margin: 0 0 10px 0;
}

.options i {
	font-size: 22px;
}

.thumb_cart {
	border: 1px solid #ddd;
	overflow: hidden;
	width: 60px;
	height: 60px;
	margin-right: 10px;
	float: left;
}

.thumb_cart img {
	padding: 1px;
	width: 58px;
	height: auto;
}

.item_cart {
	display: inline-block;
	margin-top: 20px;
}

.table.confirm th {
	text-transform: uppercase;
}

.table.confirm td {
	width: 50%;
}

#policy {
	padding: 10px 0 0 50px;
}

/*-------- 2.5 About --------*/
.feature {
	padding: 30px 30px 20px 120px;
	position: relative;
	background: #fff;
	margin-bottom: 30px;
	color: #888;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.feature i {
	margin: 0;
	position: absolute;
	top: 30px;
	left: 30px;
	padding: 0;
	margin: 0;
	width: 65px;
	height: 65px;
	line-height: 60px;
	text-align: center;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	border: 1px dashed #ccc;
	font-size: 34px;
	color: #6dcff6;
}

.features-bg {
	position: relative;
	min-height: 400px;
	background-size: cover;
}

.features-img {
	width: 100%;
	height: 400px;
	text-align: center;
	line-height: 400px;
}

#content .features-content {
	position: relative;
	padding: 11% 100px 10% 100px;
	height: 400px;
	background-color: #e04f67;
	color: #fff;
	font-size: 14px;
}

#content .features-content h3 {
	font-family: "Gochi Hand", cursive;
	color: #fff;
	font-size: 32px;
	line-height: 34px;
	text-transform: uppercase;
}

.review_strip {
	background: #fff;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	position: relative;
	padding: 30px 20px 20px 30px;
	margin-bottom: 30px;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.review_strip img {
	top: -15px;
	position: absolute;
	left: 25px;
	border: 4px solid #fff;
}

.review_strip h4 {
	font-size: 18px;
	margin: -10px 0 35px 80px;
	padding: 0;
}

.review_strip p {
	margin-bottom: 10px;
}

.review_strip .rating {
	font-size: 16px;
}

.rating {
	font-size: 18px;
}

.rating small {
	font-size: 12px;
	color: #CCC;
}

.rating .voted {
	color: #F90;
}

/*-------- 2.6 Contact us --------*/
#map_contact {
	width: 100%;
	height: 450px;
}

#directions {
	background-color: #e04f67;
	padding: 22px 0;
}

ul#contact-info {
	list-style: none;
	margin: 0 0 0 0;
	padding: 0;
}

.tape {
	position: absolute;
	left: 0;
	top: -20px;
	height: 45px;
	width: 100%;
	background: url(../img/tape.png) no-repeat center top;
	display: block;
}

.loader {
	margin-left: 5px;
	position: absolute;
}

.error_message {
	color: #F33;
	font-weight: 600;
	margin-bottom: 4px;
}

/*-------- 2.7 Login --------*/
#hero.login {
	height: auto;
	display: block;
	text-transform: none;
	font-size: 12px;
	/*background: url(../img/bg_login.jpg) no-repeat center center;*/
	background-size: cover;
}

#login {
	margin: 170px 0 60px;
	color: #333;
	text-align: left;
	background-color: #fff;
	-moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
	padding: 30px;
}

#pass-info {
	width: 98.5%;
	margin-bottom: 15px;
	color: #829CBD;
	text-align: center;
	font: 12px/25px Arial, Helvetica, sans-serif;
}

#pass-info.weakpass {
	border: 1px solid #FF9191;
	background: #FFC7C7;
	color: #94546E;
	text-shadow: 1px 1px 1px #FFF;
}

#pass-info.stillweakpass {
	border: 1px solid #FBB;
	background: #FDD;
	color: #945870;
	text-shadow: 1px 1px 1px #FFF;
}

#pass-info.goodpass {
	border: 1px solid #C4EEC8;
	background: #E4FFE4;
	color: #51926E;
	text-shadow: 1px 1px 1px #FFF;
}

#pass-info.strongpass {
	border: 1px solid #6ED66E;
	background: #79F079;
	color: #348F34;
	text-shadow: 1px 1px 1px #FFF;
}

#pass-info.vrystrongpass {
	border: 1px solid #379137;
	background: #48B448;
	color: #CDFFCD;
	text-shadow: 1px 1px 1px #296429;
}

/*-------- 2.8 Tour guide page --------*/
#tour_guide {
	text-align: center;
}

.img-circle.styled {
	border: 5px solid #fff;
}

#filters {
	padding: 20px;
	background-color: #f2f2f2;
	border-bottom: 1px solid #ededed;
	margin: 0 -15px 30px -15px;
}

a.bt_filters {
	background-color: #fff;
	border: 1px solid #ddd;
	padding: 6px 8px;
	color: #888;
	display: inline-block;
}

/*-------- 2.9 Tranfers --------*/
.transfer_container {
	background-color: #fff;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	margin-bottom: 30px;
}

.transfer_container .transfer_title {
	padding: 15px;
	position: relative;
}

.transfer_container .transfer_title .rating {
	font-size: 14px;
	margin-left: -3px;
}

.transfer_container .transfer_title h3 {
	margin: 0;
	font-size: 16px;
	text-transform: uppercase;
}

a.btn_collapse {
	display: inline-block;
	margin-bottom: 10px;
	font-weight: bold;
}

/*-------- 3.0 Hotels --------*/
.score span {
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	display: inline-block;
	;
	width: 35px;
	height: 35px;
	border: 2px solid #999;
	line-height: 32px;
	font-size: 13px;
	font-weight: bold;
	color: #999;
	margin-left: 5px;
	text-align: center;
}

.score {
	float: right;
	font-size: 11px;
	margin-top: 5px;
}

#score_detail span {
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	display: inline-block;
	;
	width: 45px;
	height: 45px;
	border: 2px solid #555;
	line-height: 42px;
	font-size: 14px;
	font-weight: bold;
	color: #555;
	margin-right: 5px;
	text-align: center;
}

#score_detail {
	font-size: 14px;
	margin-bottom: 15px;
}

#score_detail small {
	color: #999;
}

.hotel_container {
	background-color: #fff;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	margin-bottom: 30px;
}

.hotel_container .hotel_title {
	padding: 15px;
	position: relative;
}

.hotel_container .hotel_title .rating {
	font-size: 14px;
	margin-left: -3px;
}

.hotel_container .hotel_title h3 {
	margin: 0;
	font-size: 16px;
	text-transform: uppercase;
}

.hotel_container .score span {
	color: #fff;
	margin-right: 5px;
	text-align: center;
	border: 2px solid #fff;
}

.hotel_container .score {
	font-size: 11px;
	margin-top: 5px;
	position: absolute;
	bottom: 30px;
	left: 5px;
}

.hotel_container a .score {
	color: #fff;
}

/* Carousel */
.carousel .item {
	margin: 3px;
}

.carousel .item img {
	display: block;
	width: 100%;
	height: auto;
}

ul.list_icons {
	margin: 0 0 020px 0;
	padding: 0;
	list-style: none;
}

ul.list_icons li i {
	font-size: 16px;
}

.modal-dialog {
	margin-top: 80px;
}

#price_single_main.hotel {
	padding-top: 40px;
}

.hotel_container .short_info.hotel {
	padding-left: 10px;
}

/*-------- 3.1 Wishlist --------*/
.wishlist_close {
	position: absolute;
	top: 10px;
	right: 25px;
	width: 30px;
	height: 30px;
	z-index: 2;
	font-size: 20px;
	line-height: 25px;
	font-size: 18px;
	text-align: center;
	cursor: pointer;
	color: #F66 !important;
}

.wishlist_close:before {
	font-style: normal;
	font-weight: normal;
	font-family: "icon_set_1";
	font-size: 30px;
	content: "\72";
	color: #F66;
	position: absolute;
	left: 0;
	top: 3px;
}

/*-------- 3.2 Map listing --------*/
.marker_info_2 {
	width: 240px;
	height: 340px;
	border-radius: 3px;
	text-align: center;
	background: #FFF;
	background: rgba(255, 255, 255, 0.9);
	position: relative;
	z-index: 9999999;
	font-family: "Montserrat", Arial, sans-serif;
}

.marker_info_2 img {
	display: block;
	-webkit-border-top-left-radius: 3px;
	-webkit-border-top-right-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-topright: 3px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}

.marker_info_2 h3 {
	font-size: 18px;
	line-height: 18px;
	text-transform: uppercase;
	margin-bottom: 10px;
}

.marker_info_2 span {
	display: block;
	padding: 0px 20px;
	font-size: 12px;
	line-height: 16px;
	color: #7a7f82;
	margin-bottom: 15px;
}

.marker_info_2:after {
	right: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(0, 0, 0, 0);
	border-right-color: rgba(255, 255, 255, 0.9);
	border-width: 10px;
	margin-top: -10px;
}

.full-height {
	height: 100%;
}

.row-height {
	height: 100%;
	min-height: 100%;
}

.map-right {
	height: 100%;
	min-height: 100%;
	padding: 0px;
}

.content-left {
	height: 100%;
	min-height: 100%;
	overflow-y: scroll;
	padding-top: 115px;
}

.view_on_map {
	position: relative;
	cursor: pointer;
	padding: 8px 10px 0 30px;
	margin-top: 10px;
	margin-left: -15px;
	margin-right: -15px;
	margin-bottom: -8px;
	display: block;
	border-top: 1px solid #ededed;
}

.view_on_map:before {
	font-style: normal;
	font-weight: normal;
	font-family: "fontello";
	font-size: 14px;
	content: "\ecea";
	color: #e04f67;
	position: absolute;
	left: 15px;
	top: 7px;
}

/*============================================================================================*/
/* 3.  COMMON  */
/*============================================================================================*/

.box_style_1 {
	background: #fff;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	border: 1px solid #ddd;
	margin-bottom: 25px;
	padding: 30px;
	position: relative;
	color: #666;
}

.box_style_3 {
	padding: 20px;
	position: relative;
	background: #fff;
	margin-bottom: 30px;
	color: #888;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	text-align: center;
}

.box_style_3 h3 {
	font-size: 20px;
}

.box_style_3 i {
	margin: auto;
	margin-bottom: 20px;
	display: block;
	width: 80px;
	height: 80px;
	line-height: 80px;
	text-align: center;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	border: 1px dashed #ccc;
	font-size: 42px;
	color: #6dcff6;
}

.box_style_4,
.box_style_2 {
	background: #fff;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	margin-bottom: 25px;
	padding: 20px;
	position: relative;
	text-align: center;
	border: 1px solid #ddd;
}

.box_style_4:after,
.box_style_4:before {
	content: "";
	position: absolute;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	bottom: 100%;
	left: 50%;
	margin-left: -10px;
}

.box_style_4:before {
	border-bottom: 10px solid #ccc;
	margin-bottom: 0;
}

.box_style_4:after {
	border-bottom: 10px solid #fff;
	margin-bottom: -1px;
	z-index: 1;
}

.box_style_2 i,
.box_style_4 i {
	font-size: 52px;
	margin-top: 10px;
	display: inline-block;
}

.box_style_2 a.phone,
.box_style_4 a.phone {
	font-size: 26px;
	display: block;
	margin-bottom: 20px;
}

/* Input form style */
.form-control {
	font-size: 16px;
	color: #333;
	height: 40px;
	border-radius: 3px;
}

#booking .form-group {
	position: relative;
}

.expose {
	position: relative;
}

#overlay {
	background: rgba(0, 0, 0, 0.4);
	display: none;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
	bottom: 0;
}

/* Error validate style */
label.error {
	font-size: 11px;
	position: absolute;
	top: -15px;
	right: -30px;
	z-index: 99;
	height: 25px;
	line-height: 25px;
	background-color: #e34f4f;
	color: #fff;
	font-weight: normal;
	padding: 0 6px;
}

label.error:after {
	content: '';
	position: absolute;
	border-style: solid;
	border-width: 0 6px 6px 0;
	border-color: transparent #e34f4f;
	display: block;
	width: 0;
	z-index: 1;
	bottom: -6px;
	left: 20%;
}

/* Scroll top button */
#toTop {
	width: 40px;
	height: 40px;
	background-color: rgba(0, 0, 0, 0.6);
	text-align: center;
	padding: 10px;
	line-height: 20px;
	position: fixed;
	bottom: 10px;
	right: 10px;
	cursor: pointer;
	display: none;
	color: #fff;
	font-size: 20px;
}

#toTop:before {
	font-style: normal;
	font-weight: normal;
	font-family: "fontello";
	content: "\e899";
}

/*Background colors*/
.white_bg {
	background-color: #fff
}

/*Spacing*/
.add_bottom_15 {
	margin-bottom: 15px;
}

.add_bottom_30 {
	margin-bottom: 30px;
}

.add_bottom_45 {
	margin-bottom: 45px;
}

.add_bottom_60 {
	margin-bottom: 60px;
}

.nomargin_top {
	margin-top: 0 !important;
}
.no-margin {
	margin:0 !important;
}
.margin-top-5 {
	margin-top: 5px !important;
}
.margin-top-10 {
	margin-top: 10px !important;
}
.margin-top-15 {
	margin-top: 15px !important;
}
.margin-top-70 {
	margin-top: 70px !important;
}
.margin-right-15 {
	margin-right: 15px !important;
}
.margin-right-20 {
	margin-right: 20px !important;
}
.margin-right-30 {
	margin-right: 30px !important;
}
.margin-right-40 {
	margin-right: 40px !important;
}
.margin-right-50 {
	margin-right: 50px !important;
}


/*List*/
ul.list_ok {
	list-style: none;
	margin: 0 0 20px 0;
	padding: 0;
	line-height: 24px;
}

ul.list_ok li {
	position: relative;
	padding-left: 25px;
}

ul.list_ok li:before {
	font-style: normal;
	font-weight: bold;
	font-family: "icon_set_1";
	font-size: 14px;
	content: "\6c";
	color: #e36f22;
	position: absolute;
	left: 0;
	top: 0;
}

ol.list_order {
	list-style: none;
	margin: 0 0 20px 0;
	padding: 0;
}

ol.list_order li {
	position: relative;
	padding-left: 40px;
	margin-bottom: 15px;
}

ol.list_order li span {
	background-color: #e36f22;
	color: #fff;
	position: absolute;
	left: 8px;
	top: 0;
	text-align: center;
	font-size: 15px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	line-height: 24px;
	font-weight: bold;
}

/** Pagination **/
.pagination > li > a,
.pagination > li > span {
	color: #888;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
	color: #333 !important;
	background-color: #f9f9f9;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	background-color: #555;
	border-color: #555;
}

/* Drop down select footer */
.styled-select select {
	background: transparent;
	width: 115%;
	padding: 7px 5px 5px 15px;
	border: 0;
	border-radius: 0;
	height: 37px;
	margin: 0;
	font-weight: 400;
	-moz-appearance: window;
	-webkit-appearance: none;
	cursor: pointer;
	color: #999;
}

.styled-select {
	width: 100%;
	overflow: hidden;
	height: 40px;
	background: #434343 url(../img/down_arrow_select.png) no-repeat right center;
	border: none;
	margin-bottom: 15px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}

.styled-select select::-ms-expand {
	display: none;
}

.styled-select select:focus {
	outline: none;
}

/* Drop down select filters */
.styled-select-filters select {
	background: transparent;
	width: 115%;
	padding: 7px 5px 5px 10px;
	border: 0;
	border-radius: 0;
	height: 31px;
	margin: 0;
	font-weight: 400;
	-moz-appearance: window;
	-webkit-appearance: none;
	cursor: pointer;
	font-size: 12px;
	color: #888;
}

.styled-select-filters {
	width: 100%;
	overflow: hidden;
	height: 34px;
	background: #fff url(../img/down_arrow_select_filters.png) no-repeat right center;
	border: none;
	margin: 0;
	padding: 0;
	border: 1px solid #ddd;
	display: block;
}

.styled-select-filters select::-ms-expand {
	display: none;
}

.styled-select-filters select:focus {
	outline: none;
}

/* Margin tools */
.margin_50 {
	padding-top: 50px;
	padding-bottom: 60px;
}
.margin_60 {
	padding-top: 60px;
	padding-bottom: 20px;
}
.margin_80 {
	 padding-top: 80px;
	 padding-bottom: 20px;
}
.margin_30 {
	margin-top: 30px;
	margin-bottom: 30px;
}

/* Parallax bg */
.parallax-window {
	min-height: 470px;
	background: transparent;
	position: relative;
}

.parallax-content-1 {
	display: table;
	width: 100%;
	height: 470px;
}

.parallax-content-1 > div {
	display: table-cell;
	padding: 0 15%;
	vertical-align: middle;
	text-align: center;
	color: #fff;
	font-size: 16px;
}

.parallax-content-1 div h1,
.parallax-content-1 div h3 {
	font-size: 48px;
	text-transform: uppercase;
	font-weight: bold;
	color: #fff;
}

.parallax-content-1 div h1 {
	margin-bottom: 0;
	padding-top: 40px;
}

.parallax-content-1 div h1:before,
.parallax-content-1 div h1:after {
	display: inline-block;
	width: 50px;
	height: 1px;
	content: '';
	vertical-align: middle;
	background: rgba(255, 255, 255, 0.5);
}

.parallax-content-1 div h1:before {
	margin-right: 10px;
}

.parallax-content-1 div h1:after {
	margin-left: 10px;
}

.parallax-content-1 div a i {
	color: #fff;
	opacity: 0.5;
	font-size: 72px;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.parallax-content-1 div a:hover i {
	opacity: 1;
}

/* Tours boxes*/
.tour_container {
	background-color: #fff;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	margin: 0;
	margin-bottom: 30px;
}

.img_container {
	position: relative;
	overflow: hidden;
	border: 1px solid #fff;
}

.tour_container .tour_title {
	padding: 15px;
	position: relative;
}

.tour_container .tour_title .rating {
	font-size: 14px;
	margin-left: -3px;
	color: #777;
}

.tour_container .tour_title h3 {
	margin: 0 !important;
	font-size: 18px;
}

.wishlist {
	position: absolute;
	top: 10px;
	right: 10px;
	width: 40px;
	height: 40px;
	z-index: 2;
	font-size: 20px;
	line-height: 40px;
	text-align: center;
	cursor: pointer;
}

.wishlist a {
	color: #888;
	cursor: pointer;
	display: block;
}

.strip_all_tour_list .wishlist a {
	color: #fff;
}

.wishlist:before {
	font-style: normal;
	font-weight: normal;
	font-family: "icon_set_1";
	font-size: 40px;
	content: "\72";
	color: #888;
	position: absolute;
	left: 0;
	top: 4px;
}

.strip_all_tour_list .wishlist {
	position: absolute;
	top: 10px;
	right: 25px;
	width: 30px;
	height: 30px;
	z-index: 2;
	font-size: 20px;
	line-height: 30px;
	font-size: 14px;
	text-align: center;
	cursor: pointer;
	color: #fff !important;
}

.strip_all_tour_list .wishlist:before {
	font-style: normal;
	font-weight: normal;
	font-family: "icon_set_1";
	font-size: 30px;
	content: "\72";
	color: #fff;
	position: absolute;
	left: 0;
	top: 3px;
}

.img_container a:hover img {
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
}

.img_container img {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
	-webkit-backface-visibility: hidden;
	width: 100%;
}

.short_info {
	position: absolute;
	left: 0;
	bottom: -1px;
	/*background: url(../img/shadow_tour.png) repeat-x left bottom;*/
	width: 100%;
	padding: 10px 10px 8px 5px;
	color: #fff;
}

.short_info i {
	font-size: 25px;
	display: inline-block;
	vertical-align: middle;
	font-weight: normal;
	font-style: normal;
	padding: 0;
	margin: 0;
}

.type_2 .short_info {
	position: absolute;
	top:50%;	
	width:100%;
	text-align: center;
	margin-top:-13px;
	
}
.type_2 .short_info span{color: #fff;
	font-size:20px;
	font-weight:bold;
background: rgba(0,0,0,0.5);
	padding: 10px;}


.short_info .price {
	float: right;
	font-size: 28px;
	font-weight: bold;
	display: inline-block;
}

.short_info .price sup {
	font-size: 18px;
	position: relative;
	top: -5px;
}

/* Ribbons */
.ribbon {
	position: absolute;
	top: 0;
	left: -1px;
	width: 78px;
	height: 78px;
	z-index: 1;
}

.ribbon.popular {
	/*background: url(../img/ribbon_popular.png) no-repeat 0 0;*/
}

.ribbon.top_rated {
	/*background: url(../img/ribbon_top_rated.png) no-repeat 0 0;*/
}

/* Tooltips */
.tooltip_styled {
	display: inline;
	position: relative;
	z-index: 99;
}

.tooltip-item {
	cursor: pointer;
	display: inline-block;
}

.tooltip-item::after {
	content: '';
	position: absolute;
	width: 200px;
	height: 16px;
	bottom: 100%;
	left: 50%;
	pointer-events: none;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

.tooltip_styled:hover .tooltip-item::after {
	pointer-events: auto;
}

.tooltip-content {
	position: absolute;
	z-index: 99;
	min-width: 200px;
	left: 50%;
	margin: 0 0 20px -100px;
	bottom: 100%;
	text-align: left;
	line-height: 12px;
	display: block;
	padding: 10px;
	font-size: 10px;
	color: #fff;
	box-shadow: -5px -5px 15px rgba(48, 54, 61, 0.2);
	background: rgba(0,0,0,0.7);
	opacity: 0;
	cursor: default;
	pointer-events: none;
}

.tooltip-effect-1 .tooltip-content {
	-webkit-transform: translate3d(0, -10px, 0);
	transform: translate3d(0, -10px, 0);
	-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
	transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-2 .tooltip-content {
	-webkit-transform-origin: 50% calc(100% + 10px);
	transform-origin: 50% calc(100% + 10px);
	-webkit-transform: perspective(1000px) rotate3d(1, 0, 0, 45deg);
	transform: perspective(1000px) rotate3d(1, 0, 0, 45deg);
	-webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
	transition: opacity 0.2s, transform 0.2s;
}

.tooltip-effect-3 .tooltip-content {
	-webkit-transform: translate3d(0, 10px, 0) rotate3d(1, 1, 0, 25deg);
	transform: translate3d(0, 10px, 0) rotate3d(1, 1, 0, 25deg);
	-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
	transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-4 .tooltip-content {
	-webkit-transform-origin: 50% 100%;
	transform-origin: 50% 100%;
	-webkit-transform: scale3d(0.7, 0.3, 1);
	transform: scale3d(0.7, 0.3, 1);
	-webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
	transition: opacity 0.2s, transform 0.2s;
}

.tooltip_styled:hover .tooltip-content {
	pointer-events: auto;
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
	transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
}

.tooltip_styled.tooltip-effect-2:hover .tooltip-content {
	-webkit-transform: perspective(1000px) rotate3d(1, 0, 0, 0deg);
	transform: perspective(1000px) rotate3d(1, 0, 0, 0deg);
}

.tooltip-content::after {
	content: '';
	top: 100%;
	left: 50%;
	border: solid transparent;
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: transparent;
	border-top-color: rgba(0,0,0,0.7);
	border-width: 10px;
	margin-left: -10px;
}

.tooltip-content .label {
	margin-left: 10px;
}

.tooltip-content i {
	font-size: 20px !important;
	top: 3px !important;
	left: -3px !important;
	position: relative;
}

.tooltip-content h4 {
	display: block;
	margin: 0 0 5px 0;
	line-height: 12px;
	font-size: 12px;
	color: #fff;
}

.tooltip-content a {
	font-weight: bold;
}

/* Tooltip flip */
.tooltip_flip {
	position: relative;
}

.tooltip_flip::after {
	content: '';
	position: absolute;
	width: 100%;
	height: 20px;
	bottom: 100%;
	pointer-events: none;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

.tooltip_flip:hover::after {
	pointer-events: auto;
}

.tooltip-content-flip {
	position: absolute;
	z-index: 99;
	width: 80px;
	height: 80px;
	left: -40%;
	bottom: 100%;
	text-align: center;
	color: #fff;
	opacity: 0;
	margin-bottom: 7px;
	cursor: default;
	pointer-events: none;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
	transition: opacity 0.3s, transform 0.3s;
}

.strip_all_tour_list .tooltip-content-flip {
	left: -65%;
}

.tooltip-effect-1 .tooltip-content-flip {
	-webkit-transform: translate3d(0, 10px, 0);
	transform: translate3d(0, 10px, 0);
}

.tooltip_flip:hover .tooltip-content-flip {
	opacity: 1;
	pointer-events: auto;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.tooltip-content-flip > span {
	float: left;
	width: 100%;
	padding-left: 5px;
	padding-right: 5px;
	height: 100%;
	position: relative;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
}

.tooltip-back {
	background: #e04f67;
	font-size: 12px;
	line-height: 14px;
	padding-top: 25px;
	-webkit-transform-origin: 0% 50%;
	transform-origin: 0% 50%;
	-webkit-transform: perspective(1000px) rotate3d(0, 1, 0, 90deg);
	transform: perspective(1000px) rotate3d(0, 1, 0, 90deg);
}

.tooltip_flip:hover .tooltip-content-flip > span {
	-webkit-transform: perspective(1000px) rotate3d(0, 1, 0, 0deg);
	transform: perspective(1000px) rotate3d(0, 1, 0, 0deg);
}

.tooltip-content-flip .tooltip-front::after,
.tooltip-content-flip .tooltip-back::after {
	content: '';
	position: absolute;
	top: 100%;
	width: 0;
	height: 0;
}

.tooltip-content-flip .tooltip-front::after {
	right: 0;
	border-top: 10px solid #e64b50;
	border-left: 10px solid transparent;
}

.tooltip-content-flip .tooltip-back::after {
	left: 45%;
	border-top: 10px solid #e64b50;
	border-right: 10px solid transparent;
}

/* Position  breadcrumbs */
#position {
	background-color: #000;
	padding: 10px 0;
	font-size: 13px;
}

#position ul li:first-child {
	padding-left: 20px;
}

#position ul li:first-child:before {
	content: "\eac1";
	font-style: normal;
	font-weight: normal;
	font-family: "fontello";
	position: absolute;
	left: 0;
	top: 1px;
	color: #fff;
}
span.location{position:relative;padding-left: 18px;}
span.location:before {
	content: "\eaf4";
	font-style: normal;
	font-weight: normal;
	font-family: "fontello";
	position: absolute;
	left: 0;
	top: -3px;
	color: #fff;
}

#position ul {
	margin: 0;
	padding: 0;
	color: #888;
}

#position ul li a {
	color: #fff;
	opacity: 0.7;
}

#position ul li a:hover {
	color: #fff;
	opacity: 1;
}

#position ul li {
	display: inline-block;
	padding-right: 8px;
	margin-right: 3px;
	position: relative;
}

#position ul li:after {
	content: "\e9ee";
	font-style: normal;
	font-weight: normal;
	font-family: "fontello";
	position: absolute;
	right: 0;
	top: 2px;
}

#position ul li:last-child:after {
	content: '';
}

/*Banners*/
.banner {
	position: relative;
	background: #fff url(../img/banner_bg.png) no-repeat center bottom;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	-webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	padding: 30px;
	margin-bottom: 60px;
}

.banner:after,
.banner:before {
	position: absolute;
	content: "";
	z-index: 1;
	height: 18px;
	width: 50%;
	top: 100%;
}

.banner:after {
	left: 0;
	background: url(../img/shadow-bg.png) no-repeat top left;
}

.banner:before {
	right: 0;
	background: url(../img/shadow-bg.png) no-repeat top right;
}

.banner a {
	position: absolute;
	top: 35%;
	right: 30px;
}

.banner h4 {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 32px;
	margin: 0;
	padding: 0;
	color: #e04f67;
	font-family: "Gochi Hand", cursive;
}

.banner h4 span {
	text-transform: none;
	color: #555;
}

.banner p {
	margin: 0;
	padding: 0;
}

.banner.colored {
	background: #e04f67 url(../img/banner_bg_colored.png) no-repeat center bottom;
	color: #fff;
}

.banner.colored h4 {
	color: #fff;
}

.banner.colored h4 span {
	color: #FFC;
}

/*Preloading*/
#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	bottom: 0;
	background-color: #fff;
	z-index: 999999;
}

.sk-spinner-wave.sk-spinner {
	margin: -15px 0 0 -25px;
	position: absolute;
	left: 50%;
	top: 50%;
	width: 50px;
	height: 30px;
	text-align: center;
	font-size: 10px;
}

.ie8 .sk-spinner-wave.sk-spinner {
	display: none;
}

.sk-spinner-wave div {
	background-color: #ccc;
	height: 100%;
	width: 6px;
	display: inline-block;
	-webkit-animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
	animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
}

.sk-spinner-wave .sk-rect2 {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
}

.sk-spinner-wave .sk-rect3 {
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}

.sk-spinner-wave .sk-rect4 {
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}

.sk-spinner-wave .sk-rect5 {
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
}

@-webkit-keyframes sk-waveStretchDelay {
	0%, 40%, 100% {
		-webkit-transform: scaleY(0.4);
		transform: scaleY(0.4);
	}
	20% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}
}

@keyframes sk-waveStretchDelay {
	0%, 40%, 100% {
		-webkit-transform: scaleY(0.4);
		transform: scaleY(0.4);
	}
	20% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}
}

.laptop {
	margin: auto;
	margin-top: 20px;
}

/** Collapse updated v.1.8 **/
.panel {
	border-radius: 3px;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-color: #ddd;
}

.panel-title a {
	display: block;
	color: #777;
}

.panel-default > .panel-heading {
	background-color: #fff;
	padding: 20px;
}

.panel-heading {
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}

.panel-group .panel {
	border-radius: 3px;
}

.panel-body {
	padding: 30px;
	line-height: 1.6 !important;
}

.panel-title a .indicator {
	color: #e04f67;
}

.panel-title a:hover .indicator {
	color: #333;
}

/** Blockquote **/
blockquote.styled {
	font-family: "Gochi Hand", cursive;
	line-height: 1.42857143;
}

/** tabs **/
.tab-content {
	padding: 30px;
	background-color: #fff;
	border: 1px solid #ddd;
	border-top: none;
	margin-bottom: 25px;
	-webkit-border-bottom-right-radius: 3px;
	-webkit-border-bottom-left-radius: 3px;
	-moz-border-radius-bottomright: 3px;
	-moz-border-radius-bottomleft: 3px;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}

/** Img frame in general page **/
.img-responsive.styled {
	margin-top: 10px;
	border: 5px solid#fff;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.img-left{
	float:left;
	margin: 10px 10px 0 0;
}
.img-right{
	float:right;
	margin: 10px 0 0 10px;
}

/**General icons block **/
.general_icons ul {
	list-style: none;
	padding: 0;
	margin: 0;
	margin: 30px 0 0 0;
}

.general_icons ul li {
	display: inline-block;
	text-align: center;
	padding: 0 20px 20px 0;
	white-space: nowrap;
}

.general_icons ul li:last-child {
	padding-right: 0;
}

.general_icons ul li i {
	font-size: 36px;
	display: block;
	margin-bottom: 10px;
}

/*============================================================================================*/
/* 4.  UPDATES  */
/*============================================================================================*/

/* 4.1 Home 7: search function */
#search_container {
	position: relative;
	height: 700px;
	/*background: #4d536d url(../img/slide_hero.jpg) no-repeat center top;*/
	background-size: cover;
	color: #fff;
	width: 100%;
	display: table;
	z-index: 99;
}

#search {
	display: table-cell;
	vertical-align: middle;
	padding: 0 20%;
	padding-top: 100px;
}

#search .nav-tabs {
	border-radius: 0;
	font-size: 12px;
	border: 0;
	border-bottom: 1px solid transparent;
	margin-left: 10px;
	text-transform: uppercase;
}

#search .nav-tabs > li > a {
	border-radius: 0;
	border: 0;
	color: #fff !important;
}

#search .tab-content {
	border-radius: 0;
	border: 0;
	color: #333;
}

#search .nav-tabs > li > a {
	background: rgba(0, 0, 0, 0.40);
	padding-bottom: -2px;
}

#search .nav-tabs > li.active > a,
#search .nav-tabs > li.active > a:hover,
#search .nav-tabs > li.active > a:focus {
	color: #fff;
	background-color: #e14d67;
}

.tab-pane h3 {
	color: #999;
	margin: 0 0 20px 0;
}

.tab-pane h3 {
	color: #999;
	margin: 0 0 20px 0;
}

.dd-select {
	border-radius: 3px !important;
	border: solid 1px #ccc;
	position: relative;
	cursor: pointer;
	height: 40px;
	font-weight: normal !important;
}

.dd-desc {
	color: #aaa;
	display: block;
	overflow: hidden;
	font-weight: normal;
}

.dd-selected {
	overflow: hidden;
	display: block;
	padding: 0 0 0 10px !important;
	font-weight: normal !important;
	color: #444 !important;
}

.dd-pointer {
	width: 0;
	height: 0;
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -3px;
}

.dd-pointer-down {
	border: solid 5px transparent;
	border-top: solid 5px #999 !important;
}

.dd-pointer-up {
	border: solid 5px transparent !important;
	border-bottom: solid 5px #000 !important;
	margin-top: -8px;
}

.dd-option {
	padding: 10px;
	display: block;
	border-bottom: solid 1px #ddd;
	overflow: hidden;
	text-decoration: none;
	color: #333;
	cursor: pointer;
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
}

.dd-options > li:last-child > .dd-option {
	border-bottom: none;
}

.dd-options li .dd-option {
	height: 45px;
	line-height: 25px !important;
}

.dd-option:hover {
	background: #f9f9f9 !important;
	color: #000;
}

.dd-option-selected {
	background: #f9f9f9 !important;
	color: #444 !important;
}

.dd-option-image,
.dd-selected-image {
	vertical-align: middle;
	float: left;
	margin-right: 10px !important;
	max-width: 64px;
}

.dd-image-right {
	float: right;
	margin-right: 15px;
	margin-left: 5px;
}

.dd-container {
	position: relative;
}

​ label.dd-selected-text {
	font-weight: normal !important;
}

.dd-option-text {
	line-height: 14px !important;
}

.dd-selected-image {
	margin-top: 7px;
}

.dd-select label,
.dd-options label {
	font-weight: normal !important;
	cursor: pointer !important;
}

.radio_fix {
	float: left;
	margin-top: 35px;
}

/*-------- 4.1 Faq --------*/
#faq h2 {
	margin: 0 0 20px 0;
	font-size: 24px;
}

/*-------- 4.2 Pricing tables --------*/
.plans {
	margin: 0px auto 50px;
	zoom: 1;
}

.plans:before,
.plans:after {
	content: '';
	display: table;
}

.plans:after {
	clear: both;
}

.plan {
	margin: 10px 0;
	padding: 20px;
	text-align: center;
	background: #fafafa;
	background-clip: padding-box;
	border: solid #dddddd;
	min-height: 435px;
	border-width: 1px 0 1px 1px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	background-color: #fff;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.col-md-4.plan:first-child {
	margin-right: -30px;
	margin-left: 30px;
}

.plan:last-child {
	border-width: 1px;
}

.plan-title {
	position: relative;
	margin: -20px -10px 20px;
	padding: 20px;
	line-height: 1;
	font-size: 18px;
	text-transform: uppercase;
	font-weight: bold;
	border-bottom: 1px dotted #ccc;
}

.plan-title:before {
	content: '';
	position: absolute;
	bottom: -1px;
	left: 0;
	right: 0;
	height: 1px;
}

.plan-price {
	margin: 0 auto 20px;
	width: 110px;
	height: 110px;
	line-height: 110px;
	font-size: 30px;
	font-weight: bold;
	color: white;
	background: #4b4b4b;
	border-radius: 100px;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	display: inline-block;
	text-align: center;
	font-family: "Helvetica Neue", Arial;
}

.plan-price > span {
	font-size: 12px;
	font-weight: normal;
	color: rgba(255, 255, 255, 0.9);
}

.plan-features {
	margin-bottom: 20px;
	line-height: 2;
	font-size: 12px;
	text-align: center;
}

ul.plan-features {
	padding: 0;
	margin: 0 0 30px 0;
}

.plan-features li {
	list-style: none;
}

.plan-tall {
	margin: 0;
	background: #fff;
	border-width: 1px;
	border-radius: 4px;
	margin-right: -30px;
	position: relative;
	z-index: 100;
	border: solid #83c99f;
	position: relative;
}

.ribbon_table {
	width: 99px;
	height: 97px;
	position: absolute;
	left: -7px;
	top: -7px;
	display: block;
	/*background: url(../img/ribbon.png) no-repeat 0 0;*/
	z-index: 101;
}

.plan-tall > .plan-title {
	font-size: 18px;
}

.plan-tall > .plan-price {
	margin: 0 auto 20px;
	height: 130px;
	width: 130px;
	line-height: 130px;
	font-size: 30px;
	font-weight: bold;
	color: white;
	background: #f26d7d;
	border-radius: 130px;
	-webkit-border-radius: 130px;
	-moz-border-radius: 130px;
	display: inline-block;
	text-align: center;
}

.plan-tall > .plan-features {
	font-size: 14px;
}

.plan-tall > .plan-button {
	padding: 0 16px;
	line-height: 32px;
}

.plan-tall + .plan {
	border-left: 0;
}

/* Pricing tables style 2 */
#pricing_2 {
	margin-top: 20px;
}

.ribbon_2 {
	width: 99px;
	height: 97px;
	position: absolute;
	left: -5px;
	top: -5px;
	display: block;
	/*background: url(../img/ribbon.png) no-repeat 0 0;*/
	z-index: 101;
}

.pricing-table {
	text-align: center;
	font-weight: 400;
	margin-bottom: 30px;
	background: #fff;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	position: relative;
	padding-bottom: 5px;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.pricing-table:hover {
	-moz-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .5);
	-webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .5);
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .5);
}

.pricing-table.green {
	text-align: center;
	font-weight: 400;
}

.pricing-table.black {
	text-align: center;
	font-weight: 400;
}

.pricing-table-sign-up {
	border-top: 1px solid #ededed;
	padding: 10px 10px 5px 10px;
	text-align: center;
	margin-top: 30px;
}

.pricing-table-features p {
	padding: 10px 20px;
	text-align: center;
	margin: 10px 0;
}

.pricing-table-features p:nth-child(2n) {
	background: #f8f8f8;
	padding: 10px 0;
}

.pricing-table-features,
.pricing-table-space {
	background: #fff;
}

.pricing-table p {
	padding: 5px;
	margin-top: 5px;
	font-size: 110%;
	font-weight: 400;
	background: #fff;
}

.pricing-table p strong {
	font-weight: 600;
}

.pricing-table .pricing-table-header {
	color: #fff;
	padding: 0px;
}

.pricing-table-header .heading {
	display: inline-block;
	width: 100%;
	padding: 15px 0px;
	text-transform: uppercase;
	font-weight: 800;
	font-size: 18px;
}

.pricing-table.green .heading {
	background: #83c99f;
}

.pricing-table.black .heading {
	background: #333333;
}

.pricing-table .pricing-table-header .price-value {
	background: #fff;
}

.pricing-table.green .pricing-table-header .price-value {
	background: #fff;
}

.pricing-table.black .pricing-table-header .price-value {
	background: #fff;
}

.pricing-table-header .price-value {
	display: inline-block;
	width: 100%;
	padding: 10px 0px;
	background: #1ABC9C;
	font-family: "Helvetica Neue", Arial;
	font-weight: bold;
	color: #555;
	border-bottom: 1px solid #ededed;
	margin-bottom: 15px;
}

.pricing-table-header .price-value span {
	font-weight: 800;
	font-size: 36px;
	line-height: 36px;
}

.pricing-table-header .price-value span.mo {
	font-size: 22px;
	font-weight: 400;
}

.question_box h3 {
	font-size: 18px;
	text-align: left;
	line-height: 22px;
	margin-bottom: 10px;
}

.question_box {
	padding-left: 40px;
	position: relative;
	margin-bottom: 30px;
}

.question_box:before {
	content: "\ec7e";
	font-family: "fontello";
	font-style: normal;
	font-weight: normal;
	text-decoration: inherit;
	font-size: 30px;
	position: absolute;
	color: #999;
	top: 5px;
	left: 0;
}

/*-------- 4.4 Common --------*/
.badge_save {
	position: absolute;
	top: 0;
	right: 0;
	width: 65px;
	height: 77px;
	color: #fff;
	text-align: center;
	text-transform: uppercase;
	background: url(../img/badge_save.png);
	font-size: 11px;
	line-height: 12px;
	padding-top: 32px;
}

.badge_save strong {
	display: block;
	font-size: 14px;
	font-weight: bold;
}

.input-group input {
	height: 34px;
}

/* Promo_full (v.1.7)*/
.promo_full {
	height: auto;
	/*background: url(../florence.jpg) no-repeat center center;*/
	background-attachment: fixed;
	background-size: cover;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	position: relative;
}

.promo_full_wp {
	display: table;
	width: 100%;
	height: auto;
}

.promo_full_wp > div {
	display: table-cell;
	padding: 10% 15%;
	vertical-align: middle;
	text-align: center;
	color: #fff;
	font-size: 16px;
}

.promo_full_wp div h3 {
	font-size: 48px;
	text-transform: uppercase;
	font-weight: bold;
	color: #fff;
	
}

.promo_full_wp div h3 span{
	/*background:rgba(0,0,0,0.5);*/
	padding:10px;
	color: #fff;
}

.promo_full_wp div .btn_1{color:#FFF;border-color: #FFF;}

.promo_full_wp div a i {
	color: #fff;
	opacity: 0.5;
	font-size: 72px;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.promo_full_wp div a:hover i {
	opacity: 1;
}

/* Map filter (v.1.8)*/
#map_filter {
	position: fixed;
	bottom: 0;
	left: 0;
	background-color: rgba(255, 255, 255, 0.7);
	z-index: 2;
	width: 100%;
}

#map_filter ul {
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: center;
}

#map_filter ul li {
	display: inline-block;
	text-align: center;
}

#map_filter ul li a {
	display: inline-block;
	padding: 15px;
	color: #444;
	min-width: 120px;
	margin: 0 -3px;
}

#map_filter ul li a:hover,
#map_filter ul li a.active {
	background-color: #fff;
	color: #e04f67;
}

#map_filter ul li a i {
	display: block;
	font-size: 36px;
	margin: 5px 0;
}

/* Map contact (v.1.9)*/
#map_contact.contact_2 {
	margin-top: 98px;
}

/*============================================================================================*/
/* 5.  UPDATES Version 3  */
/*============================================================================================*/

/*-------- 5.1 Ribbons css --------*/
.ribbon_3 {
	position: absolute;
	left: 10px;
	top: -5px;
	z-index: 20;
	overflow: hidden;
	width: 75px;
	height: 75px;
	text-align: right;
}

.ribbon_3 span {
	font-size: 10px;
	font-weight: bold;
	color: #FFF;
	text-transform: uppercase;
	text-align: center;
	line-height: 20px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	width: 100px;
	display: block;
	background: #79A70A;
	background: linear-gradient(#9BC90D 0%, #79A70A 100%);
	box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
	position: absolute;
	top: 19px;
	left: -21px;
}

.ribbon_3 span::before {
	content: "";
	position: absolute;
	left: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid #638908;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #638908;
}

.ribbon_3 span::after {
	content: "";
	position: absolute;
	right: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid transparent;
	border-right: 3px solid #638908;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #638908;
}

.ribbon_3.popular span {
	background: linear-gradient(#f14f3f 0%, #cb4335 100%);
}

.ribbon_3.popular span::before {
	border-left: 3px solid #a3362a;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #a3362a;
}

.ribbon_3.popular span::after {
	border-left: 3px solid transparent;
	border-right: 3px solid #a3362a;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #a3362a;
}

/*-------- 5.2 Footer styles --------*/
/* Footer 3 */
footer#pattern_2 {
	/*background: #333 url(../img/pattern_3.png) repeat 0 0;*/
}

footer #logo {
	margin-top: 20px;
}

/* Footer 4 */
#newsletter_container {
	background-color: #f8f8f8;
	border-top: 1px solid #ededed;
}

#newsletter_container h3 {
	font-size: 32px;
	font-family: "Lato", Arial, sans-serif;
	font-weight: 300;
	margin: 0 0 20px 0;
}

#newsletter_container input.form-control#email_newsletter_2 {
	margin-bottom: 0;
	background-color: #fff;
	border: none;
	border: 2px solid #ededed;
	height: 49px;
	font-size: 15px;
	padding-left: 20px;
	width: 380px;
	margin-top: -2px;
	margin-right: 5px;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}

#newsletter_container input.form-control#email_newsletter_2:focus {
	border-color: unset;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: #444;
}

#newsletter_container #submit-newsletter_2.btn_1 {
	padding: 14px 35px;
}

/* Footer 5 */
ul.tweet_list li{
	padding-left: 30px;
	/*background: url(../img/twitter_icon.svg) no-repeat 0 5px;*/
}
.tweets-next,
.tweets-prev {
	display: inline-block;
	font-size: 28px;
	color: #FFF;
	margin-top: 5px;
	width: 28px;
	padding: 0;
	text-align: left;
}

.tweets-prev {
	margin-right: 5px;
}

.tweet_avatar {
	float: left;
}

.tweet_time a{
	display: block;
	margin-bottom: 5px;
	color: #fdf7ac;
}

a.bx-next, a.bx-prev{
	color: #fff;
}

a.bx-next:hover, a.bx-prev:hover{
	color: #fdf7ac;
}

.tweet_text a{
	text-decoration: underline;
}

.tweet-control {
	margin-left:15px;
}

/* Footer 6 */
footer#pattern_3 {
	/*background: #333 url(../img/pattern_4.png) repeat 0 0;*/
}
footer .post{
	position:relative;
	min-height:62px;
	padding-left:80px;
	padding-top:5px;
	margin-bottom:10px;	
}

footer .post:last-child{
	margin-bottom:5px;	
}

footer .post figure{
	position:absolute;
	left:0;
	top:0;
	width:62px;
	height:62px;
}

footer .post h4{
	font-size:13px;
	color:#ffffff;
	margin-bottom:6px;	
}

footer.post i{
	font-size:14px;
	padding-right:10px; 
}

footer .gallery-image {
  overflow: hidden;
}

footer .gallery-image a {
  max-width: 33.33%;
  padding-right: 10px;
  padding-bottom: 10px;
  float: left;
}

footer .gallery-image img {
  width: 100%;
  transition: 0.3s ease-in-out;
}

/*-------- 5.3 Header versions --------*/
/* Header 4*/
#opening{
	display:inline-block;
	margin-left:15px;
}

#social_top a i{
	font-size:13px;
}

/* Header 5*/
#lang_top i{
		font-size:14px;
}

.dropdown-menu#log_out{
	text-align:center;
}

.dropdown-menu#log_out p{
	color:#999;
}

/* Header 6*/
 .dropdown-mini .dropdown-menu {
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  float: right;
  left: auto;
  text-align:left;
  padding: 0;
  right: 0;
  min-width:100px;
  width: auto;
  top:100%;
  font-size:12px;
  font-weight: normal;
    text-shadow:none;
	text-transform:none !important;
	border-top: 2px solid #e04f67;
}

 .dropdown-mini .dropdown-menu:before {
	bottom: 100%;
	right: 10%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-bottom-color: #e04f67;
	border-width: 7px;
	margin-left: -7px;
}
 .dropdown-mini .dropdown-menu ul#lang_menu{
	 padding:0;
	 margin:0;
	 text-align:left;
}

 .dropdown-mini .dropdown-menu ul#lang_menu li{
	padding:0;
	margin:0;
	width:100%;
}

 .dropdown-mini .dropdown-menu ul#lang_menu li a{
	 color:#555 !important;
	 text-align:left;
	 border-bottom:1px solid #ededed;
	 padding:8px;
	 display:block;
}

 .dropdown-mini .dropdown-menu ul#lang_menu li a:hover{
	 background-color:#f9f9f9;
	 color:#e04f67 !important;
	 padding-left:15px;
}

a#lang_link, a#currency_link{
	position:relative;
	display:inline-block;
	padding-left:20px;
}

#lang_link:before, #currency_link:before{
	font-style: normal;
 	font-weight: normal;
 	font-family: "fontello";
    font-size:14px;
	position:absolute;
	left:0;
	top:0;
}

#lang_link:before{content: "\ea26";}

#currency_link:before{content: "\e8b8";}

/*-------- 5.4 Gallery overlay --------*/
.img_wrapper_gallery {
	margin: 0;
	padding:0;
	margin-top: 20px;
	border: 4px solid#fff;
	background-color:#000;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.img_container_gallery a:hover img {
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
	opacity: 0.6;
}

.img_container_gallery img {
	-webkit-transform: scale(1);
	transform: scale(1);
	-moz-transition: all 0.4s;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	opacity: 1;
	-webkit-backface-visibility: hidden;
}

.img_container_gallery {
	position: relative;
	overflow: hidden;
	z-index: 0;
}

.img_container_gallery i{
	position: absolute;
	top: 50%;
	z-index: 10;
	display: none;
	color: #fff;
	font-size: 30px;
	left: 50%;
	margin-top:-15px;
	margin-left: -15px;
	display:none;
}

.img_container_gallery a:hover i{
	display: block;
}

/*-------- 5.5 Map infobox + map listing --------*/
.infoBox {padding-right:25px !important;}

.map-right #map{
	height: 100%;
	width:100%;
}

a.btn_infobox_detail:before, .btn_infobox_get_directions:before,a.btn_infobox_phone:before{
	 font-style: normal;
	 font-weight: normal;
	 font-family: "Fontello";
	 font-size:20px;
	 cursor:pointer;
}

.btn_infobox_get_directions, a.btn_infobox_phone{
	border:none;
	display:inline-block;
	color:#e04f67;
	background:none;
	cursor:pointer;
	margin: 0 5px;
	font-size:13px;
	position:relative;
	padding-left:18px;
	outline:none;
}

.btn_infobox_get_directions:hover, a.btn_infobox_phone:hover{color:#333;}

.btn_infobox_get_directions:before{
  font-size:14px;
  content: "\ecea";
  position:absolute;
  left:0;
  top:2px;
}

a.btn_infobox_phone:before{
  font-size:13px;
  content: "\eed5";
  position:absolute;
  left:0;
  top:0;
}

a.btn_infobox, .btn_infobox{
	border: none;
	font-family: inherit;
	font-size: inherit;
	color:#fff;
	background: #51bce6;
	cursor: pointer;
	padding: 5px 15px;
	display: inline-block;
	outline: none;
	font-size:11px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	text-transform:uppercase;
	font-weight:bold;
}

a.btn_infobox:hover, .btn_infobox:hover{
	background: #333;
}

.marker_tools{
	margin:10px 0 15px 0;
}

.ftg-price{color:#FFF;bottom:0;right:0px;
  height: 100%;}


.padding-top-20{padding-top:20px;}
.margin-top-40{margin-top: 40px;}
.margin-top-20{margin-top: 20px;}

#howitworks{position:fixed;height:100%;z-index:300;top:0;right:0;background:#FFF;max-width:50%;}
#howitworks .feature{padding-bottom:0;color:#333;box-shadow: none;margin:0;}
#howitworks .feature h3{font-weight: bold;margin-top: 0;text-transform: uppercase;}

.collapse.width {
    height: auto;
    -webkit-transition: width 0.35s ease;
    -moz-transition: width 0.35s ease;
    -o-transition: width 0.35s ease;
    transition: width 0.35s ease;
}


@-webkit-keyframes uil-ring-anim {
	0% {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-webkit-keyframes uil-ring-anim {
	0% {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-moz-keyframes uil-ring-anim {
	0% {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-ms-keyframes uil-ring-anim {
	0% {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-moz-keyframes uil-ring-anim {
	0% {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-webkit-keyframes uil-ring-anim {
	0% {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-o-keyframes uil-ring-anim {
	0% {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes uil-ring-anim {
	0% {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
.uil-ring-css {
	background: none;
	position: relative;
	width: 200px;
	height: 200px;
}
.uil-ring-css > div {
	position: absolute;
	display: block;
	width: 160px;
	height: 160px;
	top: 20px;
	left: 20px;
	border-radius: 80px;
	box-shadow: 0 6px 0 0 #e36f22;
	-ms-animation: uil-ring-anim 0.5s linear infinite;
	-moz-animation: uil-ring-anim 0.5s linear infinite;
	-webkit-animation: uil-ring-anim 0.5s linear infinite;
	-o-animation: uil-ring-anim 0.5s linear infinite;
	animation: uil-ring-anim 0.5s linear infinite;
}

#booking_box label{line-height: 16px;marign:5px 0;}
#booking_box label small{font-weight: normal;}
