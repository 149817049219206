ul.cbp_tmtimeline {
	margin: 45px 0 0 0;
	padding: 0;
	list-style: none;
	position: relative;
} 

/* The line */
.cbp_tmtimeline:before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	width: 5px;
	background: #e04f67;
	left: 20%;
	margin-left: -8px;
}

/* The date/time */
.cbp_tmtimeline > li {
	position: relative;
}

.cbp_tmtimeline > li .cbp_tmtime {
	display: block;
	width: 25%;
	padding-right: 100px;
	position: absolute;
	margin-top:-10px;
}

.cbp_tmtimeline > li .cbp_tmtime span {
	display: block;
	text-align: right;
}

.cbp_tmtimeline > li .cbp_tmtime span:first-child {
	font-size: 0.9em;
	color: #888;
	margin-bottom:5px;
}

.cbp_tmtimeline > li .cbp_tmtime span:last-child {
	font-size: 32px;
	font-weight:600;
}


/* Right content */
img.speaker {
	width:50px;
	height:50px;
	margin-left:15px;
	border:1px solid #fff;
}
.cbp_tmtimeline > li .cbp_tmlabel {
	margin: 0 0 15px 25%;
	background: #f2f2f2;
	  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	padding: 2em;
	position: relative;
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel {
	background: #fff;
}
.cbp_tmtimeline > li .cbp_tmlabel h2 span { 
display: block;
font-size:18px;
color:#999;
text-transform:none;
}

.cbp_tmtimeline > li .cbp_tmlabel h2 { 
	margin-top: 0px;
	padding: 0 0 10px 0;
	text-transform:uppercase;
	border-bottom: 1px solid rgba(0,0,0,0.05);
	font-size:26px;
}

/* The triangle */
.cbp_tmtimeline > li .cbp_tmlabel:after {
	right: 100%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-right-color: #f2f2f2;
	border-width: 10px;
	top: 10px;
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel:after {
	border-right-color:#fff;
}

/* The icons */
.cbp_tmtimeline > li .cbp_tmicon {
	width: 48px;
	height: 48px;
	font-family: 'fontello';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	font-size: 24px;
	line-height: 48px;
	-webkit-font-smoothing: antialiased;
	position: absolute;
	color: #e04f67;
	background:#f9f9f9;
	border-radius: 50%;
	box-shadow: 0 0 0 3px #e04f67;
	text-align: center;
	left: 19.6%;
	top: -1%;
	margin: 0 0 0 -25px;
}

.timeline_icon_audio:before {
	content: "\e87d";
}

.timeline_icon_doc:before {
	content: "\e9c1";
}

.timeline_icon_video:before {
	content: "\e812";
}

.timeline_icon_test:before {
	content: "\e9ab";
}
.timeline_icon_break:before {
	content: "\ee64";
}
.timeline_icon_point:before {
	content: "\ecea";
}
.timeline_icon_pic:before {
	content: "\ee87";
}


/* Example Media Queries */
@media screen and (max-width: 65.375em) {

	.cbp_tmtimeline > li .cbp_tmtime span:last-child {
		font-size: 1.5em;
	}
}

@media screen and (max-width: 47.2em) {
	.cbp_tmtimeline:before {
		display: none;
	}

	.cbp_tmtimeline > li .cbp_tmtime {
		width: 100%;
		position: relative;
		padding: 0 0 20px 0;
	}

	.cbp_tmtimeline > li .cbp_tmtime span {
		text-align: left;
	}

	.cbp_tmtimeline > li .cbp_tmlabel {
		margin: 0 0 30px 0;
		padding: 1em;
		font-weight: 400;
		font-size: 95%;
	}

	.cbp_tmtimeline > li .cbp_tmlabel:after {
		right: auto;
		left: 20px;
		border-right-color: transparent;
		border-bottom-color: #e8f8f5;
		top: -20px;
	}

	.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel:after {
		border-right-color: transparent;
		border-bottom-color: #f9f9f9;
	}

	.cbp_tmtimeline > li .cbp_tmicon {
		position: relative;
		float: right;
		left: auto;
		margin: -65px 5px 0 0px;
	}	
}
