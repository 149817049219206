.tags a {
	display:inline-block;
	margin:5px 14px 10px 0;
	height:33px;
	line-height:33px;
	background: #ededed url(../img/tag_bg.png) no-repeat 91% center;
	padding:0 28px 0 11px;
	color:#555;	
	-webkit-border-top-right-radius: 20px;
	-webkit-border-bottom-right-radius: 20px;
	-moz-border-radius-topright: 20px;
	-moz-border-radius-bottomright: 20px;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	transition: background .5s ease; 
}
.tags a:hover {
	background-color:#099ad1;
	color:#fff;
	text-decoration:none;
}
#cat_blog ul{
	list-style:none;
	margin:0;
	padding:0;
	font-size:14px;
}
.widget h4{
	text-transform:uppercase;
	font-size:16px;
}
#cat_blog ul li a{
	border-bottom:1px solid #ededed;
	padding:10px 0;
	display:block;
	color:#888;
}
#cat_blog ul li:last-child a{
	border-bottom:none;
	padding-bottom:0;
}
#cat_blog ul li a:hover{
	color:#e04f67;
}
ul.recent_post {
	margin:0;
	padding:0;
}
ul.recent_post li {
	padding:0 0 15px 0;
	margin-bottom:15px;
	border-bottom:1px #e7e7e7 dotted;
	list-style:none;
	line-height:18px;
	color:#888;
	font-style:italic;
}
ul.recent_post li div{
	padding-left:25px;
	font-style:normal
}
ul.recent_post li:last-child{
	border-bottom:0;
	margin-bottom:0;
	padding-bottom:0;
}
.post {
	margin-bottom:45px;
}
.post h2{
	font-size:22px;
}
.post img {
	margin-bottom:18px;
}

.post_info {
	padding:10px 0;
	border-bottom:1px #ccc dashed;
	border-top:1px #ccc dashed;
	margin-bottom:12px;
	color:#999;
}
.post_info a{
	color:#999;
}
.post_info a:hover{
	color:#333;
}
.post_info span {
	color:#ff6666;
}
.post-left {float:left;}
.post-left ul {
	margin-left:0;
	padding-left:0;
}
.post-left ul li {
	float:left;
	margin-right:10px;
	list-style:none;
}
.post-right {float:right;
}
#comments {
	padding:10px 0 0 0;
	margin-bottom:15px;	
}
#comments ul {
	padding:0;
	margin:0;
	list-style:none;	
}
#comments ol {
	padding:0;
	margin:0;
	list-style:none;
}
#comments li {
	padding:0 0 23px 0;
	list-style:none;
}
.avatar {
	float:left;
	margin-right:11px;
	
}
.avatar img {
	-moz-border-radius:3px;
	-webkit-border-radius:3px;
	border-radius:3px;	
}
.comment_right {display:table;	}
.comment_info {padding-bottom:7px;}
.comment_info span {padding:0 12px;}
#comments ol li ul li {
	padding:23px 0 0 30px;
	margin:0;
}

/* From tablet portrait to mobile */
@media (max-width: 767px)  {
.post-right {float:none;}
.post-left ul li {
	float:none;
	margin-right:0;
	margin-bottom:3px;
}

}	