.no-padding {
  padding:0 !important;
}
.no-margin {
  margin:0 !important;
}
.auto-margin {
  margin: auto !important;
}
.margin-top-5{
  margin-top: 5px !important;
}
.margin-top-10{
  margin-top: 10px !important;
}
.margin-top-20{
  margin-top: 20px !important;
}
.margin-top-30{
  margin-top: 30px !important;
}
.margin-top-40{
  margin-top: 40px !important;
}
.margin-top-50{
  margin-top: 50px !important;
}
.margin-left-5{
  margin-left: 5px !important;
}
.margin-left-10{
  margin-left: 10px !important;
}
.margin-left-20{
  margin-left: 20px !important;
}
.margin-left-30{
  margin-left: 30px !important;
}
.margin-right-5{
  margin-right: 5px !important;
}
.margin-right-10{
  margin-right: 10px !important;
}
.margin-right-20{
  margin-right: 20px !important;
}
.margin-right-30{
  margin-right: 30px !important;
}
.margin-bottom-5{
  margin-bottom: 5px !important;
}
.margin-bottom-10{
  margin-bottom: 10px !important;
}
.margin-bottom-20{
  margin-bottom: 20px !important;
}
.margin-bottom-30{
  margin-bottom: 30px !important;
}
.margin-bottom-40{
  margin-bottom: 40px !important;
}
.margin-bottom-50{
  margin-bottom: 50px !important;
}
.padding-left-10{
  padding-left: 10px !important;
}
.padding-left-20{
  padding-left: 20px !important;
}
.padding-right-10{
  padding-right: 10px !important;
}
.padding-right-20{
  padding-right: 20px !important;
}
.padding-top-10{
  padding-top: 10px !important;
}
.padding-top-20{
  padding-top: 20px !important;
}
.padding-bottom-10{
  padding-bottom: 10px !important;
}
.padding-bottom-20{
  padding-bottom: 20px !important;
}
.full-width{
  width:100%;
}
.bold{
  font-weight: bold;
}
.relative {
  position: relative;
}
.display-block {
  display: block;
}
.display-inline-block {
  display: inline-block;
}
.uppercase {
  text-transform: uppercase;
}
.font-x5 {
  font-size: 5em !important;
}
.font-x4 {
  font-size: 4em !important;
}
.font-x3 {
  font-size: 3em !important;
}
.font-x2 {
  font-size: 2em !important;
}
.font-x1 {
  font-size: 1em !important;
}
.f_none {
  float:none;
}
.v_align_m{
  vertical-align:middle !important;
}
.v_align_t{
  vertical-align:top !important;
}
.v_align_b{
  vertical-align: bottom !important;
}
